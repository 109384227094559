import { useRef, useEffect } from 'react'
import * as d3 from 'd3';
import { Typography } from '@mui/material';
import { useFlightMap } from 'FlightMap/FlightMapProvider';
import TraceModel from 'Models/TraceModel';


export default function TraceChart(props) {
  const color = props.color || TraceModel.color;
  const height = props.height || 130;

  const { brushMarker } = useFlightMap();
  const svgRef = useRef();
  const { tracePath } = props;


  useEffect(() => {
    if (!tracePath) return;
    draw(svgRef, tracePath, brushMarker, { color, height });
  }, [tracePath]);

  return (<>
    <Typography variant="caption">{props.title}</Typography>
    <svg ref={svgRef} width="100%">
      <g className="altFt" />
      <g className="groundSpeed" />
    </svg>
  </>
  );

}

function draw(svgRef, tracePath, brushMarker, options) {
  const columns = ['altFt', 'groundSpeed']
  const titles = {
    altFt: 'Pressure Altitude',
    groundSpeed: 'Ground Speed'
  }

  const setBrushPosition = (point) => {
    if (!brushMarker) return;
    brushMarker.setLatLng([point.lat, point.lng]);
    const markerElement = brushMarker.getElement();
    if (markerElement) {
      markerElement.style.transform = markerElement.style.transform + ` rotate(${point.bearing}deg)`
    }
  }


  const p0 = tracePath.find(p => p.bearing);
  setBrushPosition(p0);

  const margin = { top: 20, right: 10, bottom: 10, left: 25 };

  const width = svgRef.current.clientWidth - margin.left - margin.right;
  const height = options.height - margin.top - margin.bottom;
  const svgHeight = 2 * (height + margin.top + margin.bottom)

  const svg = d3.select(svgRef.current)
    .attr('height', svgHeight);

  const xScale = d3.scaleLinear()
    .domain(d3.extent(tracePath, d => d.t))
    .range([0, width]);

  columns.forEach((dataKey, i) => {

    const g = svg.select('.' + dataKey)
      .attr('transform', `translate(${margin.left}, ${margin.top + (i * height) + (i * 20)})`);

    const filteredData = tracePath.filter(d => isFinite(d[dataKey]) && d[dataKey] !== null && d[dataKey] !== undefined);


    const yExtent = d3.extent(filteredData, d => d[dataKey]);

    yExtent[0] = 0;

    const y = d3.scaleLinear()
      .domain(yExtent)
      .range([height, 0]);

    const line = d3.line()
      .x(d => xScale(d.t))
      .y(d => y(d[dataKey]));

    const area = d3.area()
      .x(d => xScale(d.t))
      .y0(d => y(yExtent[0]))
      .y1(d => y(d[dataKey]));

    g.append('path')
      .datum(filteredData)
      .attr('fill', 'whitesmoke')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 0)
      .attr('d', area);


    g.append('path')
      .datum(filteredData)
      .attr('fill', 'none')
      .attr('opacity', 1)
      .attr('stroke', options.color)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1.5)
      .attr('d', line);


    g.append('text')
      .text(titles[dataKey])
      .style('font-size', '0.65rem')
      .attr('dy', -20)
      .style('transform', `rotate(-90deg) translate(-100px, 10px)`);


    g.selectAll(`text.brush.metric-${i}`).data([
      { key: dataKey, format: d3.format('.0f'), yScale: y }
    ])
      .enter().append('text')
      .attr('class', `brush metric-${i}`)
      .attr('x', 0)
      .attr('y', 50)
      .attr('dy', '-0.8em')
      .style('font-size', 10)
      .style('fill', '#777')
      .style('opacity', 0)
    // .text(m.title);

  });


  let isTouchDown = false;
  let altitudeSpan;
  let groundSpeedSpan;

  const brushLabels = svg.selectAll('text.brush');

  const brush = svg.selectAll('line.brush').data(['brush'])
    .enter().append('line')
    .attr('class', 'brush')
    .attr('x1', 0)
    .attr('y1', svgHeight)
    .attr('x2', 0)
    .attr('y2', 0)
    .style('stroke', '#777')
    .style('stroke-width', 1)
    .style('stroke-opacity', 0);

  const down = e => {
    e.preventDefault();

    isTouchDown = true;

    // Initialize the spans for brushing distance and duration
    altitudeSpan = (
      altitudeSpan || d3.select('.altitudeSpan')
    );

    // console.log('e.eX', e.eX);
    // console.log('e.screenY', e.screenY);
    // console.log('e.pageX', e.pageX);
    const eX = e.clientX + margin.left;
    const eY = e.clientY;


    groundSpeedSpan = (
      groundSpeedSpan || d3.select('.groundSpeedSpan')
    );


    brush
      .attr('x1', eX)
      .attr('x2', eX)
      .style('stroke-opacity', 0.6);

    brushLabels
      .attr('x', eX)
      .style('opacity', 0.9);

    svg.selectAll(`text.label`)
      .transition()
      .style('opacity', 0);


    svg.selectAll(`path.gradient-line`)
      .transition()
      .style('opacity', 1);

    showBrush(e);
  };


  const move = e => {
    if (isTouchDown) {
      e.preventDefault();
      showBrush(e);
    }
  }

  const showBrush = (e) => {
    const inputSource = e.touches ? e.touches[0] : e;


    const x = inputSource.pageX + margin.left;

    const marginOffset = margin.left  + margin.right;
    // const marginOffset = margin.left;

    const t = Math.round(xScale.invert(x - marginOffset));

    const point = tracePath.find((p, i) => (
      p.t >= t
    ));

    if (!point) {
      return;
    }

    // if (workoutPoint.clKmLatitude) {
    //   window.postMessage({
    //     onBrushMove: { workoutPoint }
    //   });
    // }

    if (point.cmMetersTraveled) {
      altitudeSpan.text(
        point.altFt
      )
    }

    // if (workoutPoint.secondsElapsed) {
    //   secondsElapsedSpan.text(
    //     Conversions.secondsToDuration(workoutPoint.secondsElapsed)
    //   );
    // }


    brush
      .attr('x1', x - marginOffset)
      .attr('x2', x - marginOffset);

    setBrushPosition(point);

    brushLabels.each(function (m) {

      d3.select(this)
        .attr('x', x - margin.left)
        .attr('y', m.yScale(point))
        .attr('text-anchor', (x - margin.left > width / 2) ? 'end' : 'start')
        .attr('dx', (x - margin.left > width / 2) ? '-0.8em' : '0.8em')
        .text(m => m.format(point[m.key]));
    });
  }


  const up = e => {
    isTouchDown = false;

    setBrushPosition(p0);

    brush.transition()
      .style('stroke-opacity', 0);

    brushLabels.transition()
      .style('opacity', 0);

    svg.selectAll(`text.label`)
      .transition()
      .style('opacity', 0.5);

    svg.selectAll(`path.gradient-line`)
      .transition()
      .style('opacity', 0.5);
  }



  svg.on('mousedown', down)
    .on('touchstart', down)
    .on('mousemove', move)
    .on('touchmove', move)
    .on('mouseup', up)
    .on('touchend', up)
    .on('touchcancel', up);

}



