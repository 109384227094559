import { useState } from 'react';
import { SearchTracesBox } from "FlightMap/SearchTraces/SearchTracesBox";
import { getFlights } from './FlightsApi';
import { useEffect } from "react";
// import { useFlightMap } from "FlightMap/FlightMap";
import { useFlightMap } from "FlightMap/FlightMapProvider";
import { Box, Button, Chip, LinearProgress, Typography, Tabs, Tab, Divider, Pagination, IconButton } from "@mui/material";
import FlightList from "FlightMap/Shared/FlightList";
import { useAuth } from "Auth/AuthProvider";
import { Link } from "react-router-dom";

import CardHeader from 'FlightMap/Shared/CardHeader';
import { Add, AirplanemodeInactive, Flight, Book } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import StickyHeader from 'FlightMap/Shared/Sticky';
import NothingFoundBox from 'FlightMap/Shared/NothingFoundBox';

export default function FlightsIndex(props) {
  const limit = 10

  const { authHeaders, isSignedIn } = useAuth();
  const { setMapFlights, setMapTraces } = useFlightMap();

  const [loading, setLoading] = useState(true);
  const [flights, setFlights] = useState([]);

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const theme = useTheme();

  useEffect(() => {
    console.log('FlightsIndex useEffect', page);
    if (!isSignedIn) {
      setLoading(false);
      return;
    }

    setLoading(true);

    setLoading(true);
    getFlights(
        authHeaders,
        { limit, offset: page * limit },
      )
      .then(data => {
        setFlights(data.flights);
        setCount(data.count);

        setMapFlights(data.flights);
        setLoading(false);

      })

  }, [page]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', pt: 0, pb: 0 }}>
      <StickyHeader>

        <CardHeader
          title="My Flights"
          toolbar={
            <>
              <IconButton
                size="small"
                component={Link}
                to="/flights/new"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark
                  }
                }}
              >
                <Flight />
              </IconButton>
            </>
          } />

        <Box sx={{ p: 1, backgroundColor: 'whitesmoke', border: '1px solid #f1f1f1', borderLeft: 0, borderRight: 0 }}>
          <SearchTracesBox />
        </Box>
      </StickyHeader>

      {
        loading && <LinearProgress />
      }


      {
        !loading && isSignedIn && flights.length > 0 &&
        <Box style={{ flex: 1 }}>
        <FlightList flights={flights} />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              size="small"
              count={Math.ceil(count / limit)}
              color="primary"
              page={page + 1}
              onChange={(e, page) => {
                setPage(page - 1);
              }}
              sx={{ justifyContent: 'center' }}
            />
          </Box>
        </Box>
      }


      {
        !isSignedIn &&
        <Box sx={{ m: 1, textAlign: 'center', flex: 1 }}>
          <Typography variant="caption">
            <p>Sign up to log your flights</p>
          </Typography>
          <Button component={Link} to="/sign-up" variant='outlined' >
            Sign Up
          </Button>
        </Box>
      }

      {
        !loading && isSignedIn && !flights.length &&
        <NothingFoundBox>
          <>
            <AirplanemodeInactive />
            <p>No flights logged yet</p>
          </>
        </NothingFoundBox>
      }
    </Box>
  )

}