import BaseModel from "Lib/BaseModel";
import { toCamelCase } from "Lib/convertCase";


export default class FlightStatsModel extends BaseModel {

  constructor(data) {
    super(data);


    const numericTotals = {};

    Object.keys(this.totals).map(name => {
      numericTotals[ toCamelCase(name) ] = +this.totals[name];
    });

    this.totals = numericTotals
  }

}
