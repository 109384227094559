import { Send } from '@mui/icons-material';
import { Card, Box, Avatar, CardHeader, CardContent, Typography, Divider, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useProfile } from 'Profile/ProfileProvider';
import WjCard from 'Lib/WjCard';

export default function ProfileCard(props) {
  const { isLoadingProfile, profile, flightStats } = useProfile();

  const avatarSx = { width: 64, height: 64, transform: 'translateY(-50%)' };

  if (isLoadingProfile || !profile) {
    return null;
  }

  return (
    <WjCard sx={{ position: 'sticky', top: 0, m: 0, overflow: 'inherit', mt: 6 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', m: 0, height: 40 }}>
        <Link to="/profile">
          {
            profile.profilePhotoUrl ?
              <Avatar src={profile.profilePhotoUrl} sx={avatarSx} />
                :
              <Avatar sx={avatarSx}>
                {profile.getInitials()}
              </Avatar>
          }
        </Link>
      </Box>
      <CardHeader
        title={<>
          {profile.getFullName()}<br />
          <small style={{ fontSize: '0.9rem', }}>{profile.pilotType}</small>
        </>}
        sx={{ p: 0, textAlign: 'center' }}
      />
      <CardContent>
        <Box sx={{ m: 1, mb: 3, display: 'flex', textAlign: 'center' }}>

          <div style={{ flex: 1 }}>
            <Typography variant="caption" sx={{ fontSize: '0.65rem' }}>Home Airport</Typography>
            <Typography variant="body2">{profile.homeAirportId}</Typography>
          </div>

          <div style={{ flex: 1 }}>
            <Typography variant="caption" sx={{ fontSize: '0.65rem' }}>Flights</Typography>
            <Typography variant="body2">{flightStats.flightCount}</Typography>
          </div>

          <div style={{ flex: 1 }}>
            <Typography variant="caption" sx={{ fontSize: '0.65rem' }}>Total Hours</Typography>
            <Typography variant="body2">{flightStats.totals.totalHours}</Typography>
          </div>

        </Box>
        <Divider sx={{ mb: 2 }} />

        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, paddingLeft: 10 }}>
            <Typography component="div" variant="caption" color="primary" sx={{ fontWeight: 'bold' }}>
              Invite a pilot.
            </Typography>
            <Typography component="div" variant="caption">
              Invite an aviation friend. It's free, but for aviation-affiliated people only.
            </Typography>
          </div>
          <div style={{ flex: 0, alignContent: 'center' }}>
            <IconButton>
              <Send />
            </IconButton>
          </div>
        </div>
      </CardContent>
    </WjCard>
  )
}