import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

export function SearchTracesBox(props) {
  const [ registrationNumber, setRegistrationNumber ] = useState(
    (props.registrationNumber || '').toUpperCase()
  );

  const navigate = useNavigate();

  const onSubmit = e => {
    e.preventDefault();
    if(registrationNumber) {
      navigate(`/flights/${registrationNumber}/traces`);
    }
    else {
      navigate(`/flights`);
    }
  }

  return (
    <Box component="form" onSubmit={onSubmit} sx={{ p: 0, backgroundColor: 'transparent' }}>
      <TextField
        // label="Find Flight Tracks"
        placeholder="Find flights by Tail Number"
        sx={{ backgroundColor: 'white', borderRadius: '6px' }}
        fullWidth
        size="small"
        value={registrationNumber}
        onChange={e => setRegistrationNumber(e.target.value.replace(/\s+/g, '').trim().toUpperCase())}
        InputProps={{
          startAdornment: <InputAdornment position="start">
            <Search />
          </InputAdornment>,
        }}
        inputProps={{
          style: { fontSize: '0.88rem' }
        }}
      />
    </Box>
  )
}

