

import React, { useRef, useState, createContext, useContext, useMemo, useCallback } from "react";
import BaseMap from "BaseMap/BaseMap"
import MapCard from "./MapCard/MapCard";
import Layout from "Layout/Layout";
import { Routes, Route, Link } from 'react-router-dom';
import RecentFlights from "./Flights/FlightsIndex";
import SearchTraces from "./SearchTraces/SearchTraces";
import TraceDetail from "./TraceDetail/TraceDetail";
import FlightDetail from "./Flights/OldFlightView";
import EditFlightDetail from "./Flights/FlightEdit";
import NewFlight from "./Flights/FlightCreate";
import { Tabs, Tab } from "@mui/material";
import { Flight, Book } from "@mui/icons-material";

export const FlightMapContext = createContext();


export default function FlightMap(props) {
  // const mapRef = useRef();

  const [mapTraces, setMapTraces] = useState([]);
  const [mapFlights, setMapFlights] = useState([]);
  // const [mapAirports, setMapAirports] = useState([]);
  const [brushMarker, setBrushMarker] = useState(null);

  return (
      <Layout>
        <BaseMap/>

        <MapCard>
          <Routes>
            <Route path="/" element={<RecentFlights />}/>
            {/* <Route path="/new" element={<NewFlight />}/> */}
            {/* <Route path="/search" element={<SearchTraces />}/> */}
            {/* <Route path="/:flightId" element={<FlightDetail />}/> */}

            <Route path="/:registrationNumber/traces" element={<SearchTraces />} />
            <Route path="/:registrationNumber/traces/:traceUid" element={<TraceDetail />} />
          </Routes>
        </MapCard>
      </Layout>
    // </FlightMapContext.Provider>
  );

}


// export const useFlightMap = () => useContext(FlightMapContext);