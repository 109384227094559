import L from "leaflet";
import * as d3 from 'd3'
import logger from "Lib/logger";
import { getAirportMarker, getAirportMarkerByTowerType } from "./airports";


// Function to create and draw Flight Bézier curve
export function drawFlightCurves(map, flightSegmentsAndAirports, callback) {
  logger('drawFlightCurves', flightSegmentsAndAirports);
  const { flightSegments, airports } = flightSegmentsAndAirports;

  const flightCurves = [];
  const mapSelection = d3.select(map.getPanes().overlayPane);
  const bounds = L.latLngBounds();


  var controlledIcon = L.divIcon({
    className: 'airport-marker',
    html: `<div class="airport-icon controlled"></div>`
  });

  var uncontrolledIcon = L.divIcon({
    className: 'airport-marker',
    html: `<div class="airport-icon uncontrolled"></div>`
  });


  flightSegments.forEach(d => {
    d.control =  getControlPoints(d.start, d.end);
    d.path = calculateSplinePoints(d.start[0], d.start[1], d.end[0], d.end[1], d.control[0], d.control[1]);

    const idClasses = d.flightIds.map(id => `flight-${id}`);

    const line = L.polyline(
      d.path,
      {
        className: `flight-curve ${idClasses.join(' ')}`,
        opacity: 0.7,
        color: '#FC5200',
        filter: 'url(#dropshadow)',
        dashArray: [2, 6]
      }
    ).addTo(map);

    bounds.extend(line.getBounds());
    flightCurves.push(line);
  });

  airports.forEach(a => {
    L.marker(
      [a.lat, a.lng],
      {
        icon: getAirportMarkerByTowerType(a),
      }
    ).addTo(map);
  })

  return callback({ flightCurves, airports, bounds });

}


export const removeFlightCurves = (flightCurves) => {
  flightCurves?.forEach(l => l.remove());
}


function getControlPoints(start, end) {
  var midLat = (start[0] + end[0]) / 2;
  var midLng = (start[1] + end[1]) / 2;

  // Calculate a perpendicular vector to offset the control point
  var offsetLat = (start[1] - end[1]) * 0.2;
  var offsetLng = (start[0] - end[0]) * 0.2;

  // Control point is offset from the midpoint
  var controlPoint = [midLat - offsetLat, midLng + offsetLng];

  return controlPoint;
}


function addAirportMarker(map, lat, lng, content) {
  var airportIcon = L.divIcon({
      className: 'airport-marker',
      html: `<div class="airport-icon"></div>`
  });

  L.marker([lat, lng], { icon: airportIcon }).addTo(map);
}


function calculateSplinePoints(lat1, lon1, lat2, lon2, controlLat, controlLon, numPoints = 100) {
  const points = [];

  for (let t = 0; t <= 1; t += 1 / numPoints) {
    const x = (1 - t) * ((1 - t) * lat1 + t * controlLat) + t * ((1 - t) * controlLat + t * lat2);
    const y = (1 - t) * ((1 - t) * lon1 + t * controlLon) + t * ((1 - t) * controlLon + t * lon2);
    points.push([x, y]);
  }

  return points;
}