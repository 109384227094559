import React, { useState } from 'react';
import { Box, IconButton, Button, Typography } from '@mui/material';
import AddAPhoto from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useAuth } from 'Auth/AuthProvider';
import logger from 'Lib/logger';
import { useSnackbar } from 'Lib/Snackbar';

const FlightPhotoUpload = (props) => {
  const { authHeaders } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setSelectedImages((prevImages) => [...prevImages, ...imageUrls]);
  };

  // const handleSave = () => {
  //   setImages((prevImages) => [...prevImages, ...selectedImages]);
  //   setSelectedImages([]);
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // Assuming 'selectedImages' contains the file URLs, you need to convert them to File objects
    selectedImages.forEach((imageUrl, index) => {
      // Convert the image URL back to a Blob (image file)
      fetch(imageUrl)
        .then(res => res.blob())
        .then(blob => {
          formData.append(`photos[]`, new File([blob], `photo-${index}.jpg`, { type: 'image/jpeg' }));
        });
    });

    try {
      // Wait until all images are added to the formData
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simple delay to ensure all images are added

      const response = await axios.post(`/flights/${props.flightId}/upload_photos.json`, formData, {
        headers: {
          ...authHeaders.headers,
          'Content-Type': 'multipart/form-data',
        },
      });

      logger(response.data);

      showSnackbar('Photos updated');

      // Assuming you want to update the photos on the page after upload
      props.onUpdate(response.data.profile_photo_urls); // Assuming the response contains an array of URLs
      // setSelectedImages([]);

    } catch (error) {
      console.error('Error uploading photos:', error);
    }
  };


  const handleCancel = () => {
    setSelectedImages([]);
  };

  const handleRemove = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>

      {/* Image Upload Well */}
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="whitesmoke"
        p={1}
        borderRadius="8px"
        textAlign="center"
        position="relative"
        width="100%"
        minHeight="80px"
        mx="auto"
      >
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="image-upload"
          type="file"
          onChange={handleImageUpload}
          multiple
        />
        <label htmlFor="image-upload">
          <IconButton
            color="default"
            aria-label="upload picture"
            component="span"
          >
            <AddAPhoto style={{ fontSize: 20, color: "#757575" }} />
          </IconButton>
        </label>
        <Typography variant="caption" color="textSecondary">
          Upload Photos
        </Typography>

        {/* Display selected images inside the well */}
        {selectedImages.length > 0 && (
          <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2} gap={1}>
            {selectedImages.map((image, index) => (
              <Box key={index} position="relative" width={80} height={80} >
                <div style={{ borderRadius: 8, overflow: 'hidden', height: '100%' }}>
                <img src={image} alt={`Selected ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => handleRemove(index)}
                  style={{
                    width: '22px',
                    height: '22px',
                    position: 'absolute',
                    top:-6,
                    right:-6,
                    overflow: 'inherit',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    border: '1px solid #757575'
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}

        {/* Save and Cancel Buttons */}
        {selectedImages.length > 0 && (
          <Box sx={{ display: 'flex' }} mt={2}>

            <Button fullWidth sx={{ flex: 1, mr: 1 }} size="small" variant="outlined" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button fullWidth size="small" variant="contained" color="primary" onClick={handleSubmit} sx={{ boxShadow: 'none', flex: 1,  }}>
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FlightPhotoUpload;