// withAuth.js
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const withAuth = (Component) => {
  const AuthenticatedComponent = (props) => {
    const location = useLocation();
    const { isSignedIn } = useAuth();

    if(!isSignedIn) {
      return <Navigate to="/sign-in" state={{ from: location }} />
    }

    return <Component {...props} />;
  };

  return AuthenticatedComponent;
};

export default withAuth;