import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchAirportSuggestions } from 'FlightMap/Flights/AirportsAutocomplete';
import { useAuth } from 'Auth/AuthProvider';

const HomeAirportAutocomplete = (props) => {
  const { authHeaders } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (inputValue) {
      fetchAirportSuggestions(inputValue, authHeaders)
      .then(airports => {
        setOptions(airports.map(a => a.getId()));
      })
    }
  }, [inputValue]);


  return (
    <Autocomplete
      freeSolo
      options={options}
      defaultValue={props.value || ""}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue.trim().toUpperCase());
      }}
      onChange={(e, newValue) => props.onChange(newValue) }
      noOptionsText="No airports found"
      renderInput={(params) => (
        <TextField {...params} label="Home Airport" required variant="outlined" />
      )}
    />
  );
};

export default HomeAirportAutocomplete;