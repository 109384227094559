import React, { useEffect, useState, useRef, useContext } from 'react';
import TraceList from "../Shared/TraceList";
import { useAuth } from 'Auth/AuthProvider';
import { useLocation, useParams } from 'react-router-dom';
import { searchTraces } from './api';
import Pagination from '@mui/material/Pagination';
import { SearchTracesBox } from './SearchTracesBox';
// import { useFlightMap } from 'FlightMap/FlightMap';
import { useFlightMap } from "FlightMap/FlightMapProvider";
import { LinearProgress, Skeleton, Box, Stack, Card } from '@mui/material';
import FlightTabs from 'FlightMap/Shared/FlightTabs';
import CardHeader from 'FlightMap/Shared/CardHeader';
import StickyHeader from 'FlightMap/Shared/Sticky';
import NothingFoundBox from 'FlightMap/Shared/NothingFoundBox';
import { AirplanemodeInactive } from '@mui/icons-material';


function SearchTraces() {
  const params = useParams();

  const limit = 10

  const [traces, setTraces] = useState([]);
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { authHeaders } = useAuth();
  const { setMapTraces } = useFlightMap();

  useEffect(() => {
    if (params.registrationNumber === undefined) return;

    if (params.registrationNumber !== registrationNumber) {
      setPage(0);
      setRegistrationNumber(params.registrationNumber);
    }

    setLoading(true);
    searchTraces(
      params.registrationNumber,
      { limit, offset: page * limit },
      authHeaders
    )
      .then(data => {
        const { count, traces } = data;
        setTraces(traces);
        setCount(count);
        setLoading(false);

        setMapTraces(traces);
      });
  }, [params.registrationNumber, page]);

  return (
    <div>
      <StickyHeader>
        <CardHeader backTo={'/flights'} title="Find Flights" />
        <Box sx={{ p: 1, backgroundColor: 'whitesmoke', border: '1px solid #f1f1f1', borderLeft: 0, borderRight: 0 }}>
          <SearchTracesBox registrationNumber={params.registrationNumber} />
        </Box>
      </StickyHeader>


      {
        loading && <>
          <LinearProgress />
          <Box sx={{ p: 1 }}>
            <Stack spacing={2}>
              {
                [1, 2, 3, 4, 5, 6].map(i => (
                  <Skeleton key={i} variant="rectangular" width="100%" height={60} />
                ))
              }
            </Stack>
          </Box>
        </>
      }
  {
    !loading && traces.length === 0 && (
      <NothingFoundBox>
        <>
          <AirplanemodeInactive />
          <p>No flights found.</p>
        </>
      </NothingFoundBox>
    )
  }

      {
        !loading && traces.length > 0 &&
        <Box style={{ flex: 1 }}>
          <TraceList
            traces={traces}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              size="small"
              count={Math.ceil(count / limit)}
              color="primary"
              page={page + 1}
              onChange={(e, page) => {
                setPage(page - 1);
              }}
              sx={{ justifyContent: 'center' }}
            />
          </Box>
        </Box>
      }
    </div>
  );
}

export default SearchTraces;