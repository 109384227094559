import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Container, Typography, Box, Paper } from '@mui/material';

const AudioTimeline = ({ audioSnippets }) => {

  const [selectedSnippet, setSelectedSnippet] = useState(null);
  const audioRef = useRef();
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const width = svgRef.current.clientWidth;
    // const width = 800;
    const height = 100;
    const padding = 10;

    window.audioSnippets = audioSnippets;

    svg.attr('height', height);

    // Scale for positioning the rects
    // const totalDuration = d3.reduce(audioSnippets, (acc, d) => acc + d.duration, 0);
    const first = audioSnippets[0];
    const last = audioSnippets[audioSnippets.length - 1];
    //
    // let time = 0;
    audioSnippets.forEach(d => {
      d.durationSeconds = d.duration / 1000;
      d.timeOffsetSeconds = d.startTime - first.startTime;
    })


    const extent = [first.timeOffsetSeconds, last.timeOffsetSeconds + last.durationSeconds];
    console.log('extent', extent);

    console.log('duration minutes: ', (extent[1] - extent[0]) / 60);


    const timeScale = d3.scaleLinear()
      .domain(extent)
      .range([padding, width - padding]);

    // Volume scale (0 to 1 range mapped to height)
    const maxIntensity = d3.max(audioSnippets, d => d3.max(d.intensityValues));

    const volumeScale = d3.scaleLinear()
      .domain([0, maxIntensity])
      .range([0, (height - 2 * padding) / 2]);

    // Bind data
    // const rects = svg.selectAll('rect')
    //   .data(audioSnippets)
    //   .enter()
    //   .append('rect')
    //   .attr('x', d => timeScale(d.startTime))
    //   .attr('y', padding)
    //   .attr('width', d => timeScale(d.startTime + (d.duration / 1000)) - timeScale(d.startTime))
    //   .attr('height', height - (2 * padding))
    //   .style('fill', 'steelblue')
    //   .style('stroke', 'steelblue')
    //   .style('stroke-opacity', 0.8)
    //   .style('fill-opacity', 0.2)
    //   .on('click', (e, d) => {
    //     console.log('clicked', d);
    //     setSelectedSnippet(d);
    //   });
        //

    const rects = svg.selectAll('g')
      .data(audioSnippets)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${timeScale(d.timeOffsetSeconds)}, 0)`)
      .attr('tabindex', 0)
      .on('keydown', (e, d) => {
        if (e.key === 'Enter') {
          setSelectedSnippet(d);
        }
      });



    const centerY = height / 2;

     // Draw volume bars emanating from the center
     rects.each(function (d) {
      // const snippetGroup = d3.select(this);
      // const durationSeconds = d.duration / 1000
      // console.log('snippetGroup durationSeconds', durationSeconds);
      // console.log('snippetGroup intensityValues', d.intensityValues.length);

      const snippetGroup = d3.select(this);
      const totalWidth = timeScale(d.durationSeconds); // Total width available for this snippet
      const numPixels = Math.floor(totalWidth); // Number of pixels we want to fill
      const numValues = d.intensityValues.length;
      const valuesPerPixel = Math.max(1, Math.floor(numValues / numPixels)); // How many values to average per pixel

       // Create the averaged intensity values
       const averagedValues = [];
       for (let i = 0; i < numValues; i += valuesPerPixel) {
         const avgValue = d3.mean(d.intensityValues.slice(i, i + valuesPerPixel));
         averagedValues.push(avgValue);
       }

       d.averagedValues = averagedValues;


      snippetGroup.selectAll('rect')
        .data(averagedValues)
        .enter()
        .append('rect')
        .attr('class', 'volume-bar')
        .attr('x', (v, i) => i * (timeScale(d.durationSeconds) / averagedValues.length))
        .attr('y', v => centerY - volumeScale(v))  // Center the bars vertically
        .attr('width', timeScale(d.durationSeconds) / averagedValues.length) // Ensure width is at least 1px
        .attr('height', v => volumeScale(v) * 2)  // Height extends both up and down
        .style('fill', 'lightgray')
        .style('fill-opacity', 0.7)
        .on('click', () => {
          setSelectedSnippet(d);
          d3.selectAll('.volume-bar').style('fill', 'lightgray');
          snippetGroup.selectAll('.volume-bar')
            .style('fill', 'orange')
            .style('fill-opacity', 1);
        })
        // .on('blur', () => {
        //   setSelectedSnippet(d);
        //   snippetGroup.selectAll('.volume-bar').style('fill', 'lightgray')
        //   .style('fill-opacity', 0.7);
        // })
    });

  }, [audioSnippets]);

   // Effect to handle audio playback
   useEffect(() => {
    if (selectedSnippet && audioRef.current) {
      audioRef.current.load(); // Reload the audio element
      audioRef.current.play(); // Optionally, start playing automatically
    }
  }, [selectedSnippet]);

  return (
    <div>
        <svg ref={svgRef} width="100%"/>
        {selectedSnippet && (
          <Box mt={2}>
            <Typography variant="h6">
              Selected Snippet: {selectedSnippet.fileName}
            </Typography>
            <audio ref={audioRef}  controls>
              <source src={`/stub_data/audio/${selectedSnippet.fileName}`} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        )}
    </div>
  );
};

export default AudioTimeline;