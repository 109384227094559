import axios from 'axios';
import logger from 'Lib/logger';
import AudioSnippetModel from 'Models/AudioSnippetModel';


export const getAudio = (authHeaders) => {

  return axios.get(`/stub_data/audio/240802_1231.json`, {...authHeaders, withCredentials: true})
    .then(response => response.data)
    .then(data => {

      const audioSnippets = data.map(f => (
        new AudioSnippetModel(f)
      ));

      logger('getAudio', audioSnippets);

      return { audioSnippets };
    });
}
