import axios from "axios";
import logger from "Lib/logger";


export const getFlightSegmentsAndAirports = (flights, authHeaders) => {
  logger("getFlightSegmentsAndAirportData", flights);
  if (!flights.length) {
    return Promise.resolve({ flightSegments: [], airports: [] });
  }

  const { flightSegmentIds, airportIds } = getFlightSegmentAndAirportIds(flights);

  return getAirports(airportIds, authHeaders)
    .then(airports => {
      logger('airports:', airports);

      const airportLookup = {};
      airports.forEach(a => {
        if (a.icao_id) {
          airportLookup[a.icao_id] = a;
        }
        airportLookup[a.arpt_id] = a;
      });

      logger('airportLookup', airportLookup);

      const flightSegments = Object.keys(flightSegmentIds).map(key => {
        const split = key.split(',');

        let departure = airportLookup[split[0]];
        let arrival = airportLookup[split[1]];

        departure = { ...departure, lat: +departure.lat, lng: +departure.lng };
        arrival = { ...arrival, lat: +arrival.lat, lng: +arrival.lng };

        const departureCoords = [departure.lat, departure.lng];
        const arrivalCoords = [arrival.lat, arrival.lng];

        const flightIds = flightSegmentIds[key].flightIds;

        return {
          key,
          flightIds,
          start: departureCoords,
          end: arrivalCoords,
          path: [departure, arrival]
        };
      });

      logger('flightSegments', flightSegments);

      return {
        flightSegments,
        airports,
      };

    });
}



const getFlightSegmentAndAirportIds = (flights) => {
    const airportsSet = new Set();
    const flightSegmentIds = {};

    flights.forEach(f => {
      const { departureAndArrivalAirports } = f.logbookEntry;

      departureAndArrivalAirports.forEach((a, i) => {
        // Skip the first airport
        if (i === 0) return;

        const departure = departureAndArrivalAirports[i - 1];
        const arrival = departureAndArrivalAirports[i];

        airportsSet.add(departure);
        airportsSet.add(arrival);

        const key = `${departure},${arrival}`

        const value = flightSegmentIds[key] || { flightIds: [] };
        value.flightIds.push(f.id);

        flightSegmentIds[key] = value;
      });
    });

    logger('flightSegmentIds', flightSegmentIds);
    logger('airportsSet', airportsSet);

    return {
      flightSegmentIds,
      airportIds: Array.from(airportsSet)
    };
}


const getAirports = (departureAndArrivalAirportIds, authHeaders) => {
  return axios.get(
    `/airports/find_by_ids.json?ids=${departureAndArrivalAirportIds.join(',')}`,
    authHeaders
  )
    .then(response => {
      const { airports } = response.data;
      return airports;
    });
}