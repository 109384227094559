import L from "leaflet";
import './airportIcon.css';
import cessnaSvg from './cessnaSvg';

const airplaneIcon = L.divIcon({
  html: cessnaSvg,
  iconSize: [32, 32],                      // Size of the icon [width, height]
  iconAnchor: [16, 16],                    // Point of the icon which will correspond to marker's location [x, y]
  popupAnchor: [16, -16],                    // Point from which the popup should open relative to the iconAnchor
  className: 'cessna-icon'
});

export default airplaneIcon;