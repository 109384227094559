import React, { useState } from 'react';
import { TextField, Chip, Box, Popper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useAuth } from 'Auth/AuthProvider';
import axios from 'axios';
import AirportModel from 'Models/AirportModel';

const TaggingTextArea = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedTags, setSelectedTags] = useState(props.selectedTags || []);
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = async (event) => {
    const value = event.target.value.toUpperCase().trim();

    setInputValue(value);

    const lastWord = value.split(' ').pop();
    if (lastWord) {
      props.fetchSuggestions(lastWord)
        .then(airports => {
          setSuggestions(airports.map(a => a.getId()));
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (event, newValue) => {
    if (event.key === 'Backspace' && inputValue === '') {
      return;
    }

    if (newValue && newValue.length > 0) {
      const lastSelected = newValue[newValue.length - 1];
      const newTags = [...selectedTags, lastSelected];
      setSelectedTags(newTags);
      props.onChange(newTags);


      setInputValue('');
      setSuggestions([]);
    }
  };

  const handleDelete = (index) => () => {
    const newSelectedTags = [...selectedTags];
    newSelectedTags.splice(index, 1);
    setSelectedTags(newSelectedTags);
  };

  return (
      <Autocomplete
        fullWidth
        multiple
        filterSelectedOptions={false}
        selectOnFocus
        value={selectedTags}
        onChange={(event, newValue) => handleSelect(event, newValue)}
        options={suggestions}
        getOptionLabel={(option) => option}
        isOptionEqualToValue={() => false}
        // Hide the clearable stuff since the space is so small
        disableClearable
        // Hide the dropdown indicator
        sx={{
          '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
          },
          '& .MuiFormControl-root': {
            paddingRight: 0,
          },
          pr: 0
        }}
        // Don't show the dropdown if there are no suggestions
        PopperComponent={(props) =>
          suggestions.length === 0 ? null : <Popper {...props} />
        }
        noOptionsText="No airports found"
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                size="small"
                color="primary"
                sx={{ fontWeight: 'bold' }}
                key={`${option}-${index}`}
                label={option}
                {...tagProps}
                tabIndex={0}
                onDelete={handleDelete(index)}
              />
            );
          })
        }
        renderInput={params => {
          return (
          <TextField
            {...params}
            margin="none"
            sx={{ m: 0, pr: 6, mr: 0 }}
            size="small"
            variant="outlined"
            fullWidth
            onChange={handleInputChange}
            placeholder="..."
            InputProps={{
              ...params.InputProps,
              style: { paddingRight: 6, marginRight: 0 },
            }}
            inputProps={{
              ...params.inputProps,
              value: inputValue,
            }}
          />
          );
        }}
      />
  );
};

const AirportsAutocomplete = (props) => {
  const { authHeaders } = useAuth();
  const onChange = props.onChange || (() => {});


  return (
    <TaggingTextArea selectedTags={props.value} onChange={onChange} fetchSuggestions={q => fetchAirportSuggestions(q, authHeaders)} />
  );
};


export const fetchAirportSuggestions = (query, authHeaders) => {
  return axios.get(`/airports/suggestions.json?q=${query}`, authHeaders)
    .then(response => {
      return response.data.airports.map(a => new AirportModel(a));
    })
};

export default AirportsAutocomplete;