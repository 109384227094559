import { Box, Chip } from "@mui/material";

export default function AirportChips(props) {
  return (

    <Box sx={{ p: 1, display: 'flex', position: 'relative', justifyContent: 'space-between' }}>
      <Box style={{ right: 20, left: 20, position: 'absolute', height: 12, borderBottom: '2px solid #dcdcdc', zIndex: 1 }} />
      {props.airports.map((a, i) => (
        <div key={i} style={{ zIndex: 2 }}>
          <Chip
            label={a}
            color="primary"
            size="small"
            sx={{ fontSize: '10px', fontWeight: 700, p: '1px' }}
          />
        </div>
      ))}
    </Box>
  );

}