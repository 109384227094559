import * as d3 from "d3";
import { timeFormat } from 'd3';

export const formatHours = d3.format(",.1f")
export const formatCount = d3.format(",.0f")
export const formatLogbookDate = d3.timeFormat("%m/%d/%Y");
export const formatInputDate = d3.timeFormat("%Y-%m-%d");
export const formatTime = d3.timeFormat("%I:%M%p");
const yyyyMmDd = timeFormat('%Y-%m-%d');


const _withDayOfWeekDateFormat = d3.timeFormat("%a %B %d, %Y");
const _shortDateFormat = d3.timeFormat("%m/%d/%Y");


const _formatDate = (d, options = {}) => {
  options = options || {};

  if(!d) {
    return '';
  }

  if(typeof d === 'string'){
    d = new Date( d.indexOf('T') > -1 ? d : (d + 'T00:00:00.000Z'));
  }

  return options.short ? _shortDateFormat(d) : _withDayOfWeekDateFormat(d);
};


export const formatDate = d => _formatDate(d, { short: false });
export const shortDateFormat = d => _formatDate(d, { short: true });

export const parseInputDate = value => {
  let parsedDate = '';
  if (typeof value === 'string' && value.indexOf('T') > -1) {
    parsedDate = value.split('T')[0];
  }
  else if(typeof value === 'date') {
    parsedDate = yyyyMmDd(value);
  }
  else if (!/^\d{4}-\d{2}-\d{2}$/.test(value)){
    parsedDate = yyyyMmDd( new Date(value) );
  }
  return parsedDate;
}