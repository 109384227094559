import axios from "axios";
import { map } from "d3";
import L from "leaflet";
import logger from "Lib/logger";
import TraceModel from "Models/TraceModel";
import FlightModel from "Models/FlightModel";


export const drawTraces = (traces, mapRef, callback) => {
  logger("drawTraces:", traces);
  if (!traces.length) {
    return;
  }

  const traceLines = [];
  const bounds = L.latLngBounds();

  traces.forEach(t => {
    const { traceUid } = t;

    axios.get(t.getUrl())
      .then(response => {
        logger("drawTraces response:", response.data.length);
        const path = response.data;
        const tracesData = path.map(t => ({ lat: t[1], lng: t[2] }));

        if (!mapRef || !mapRef.current) {
          return;
        }

        // setTimeout(() => {
          const line = L.polyline(
            tracesData,
            {
              className: `trace t-${traceUid}`,
              opacity: 0.7,
              color: t.flightId ? FlightModel.color : TraceModel.color,
              // color: t.flightId ? '#FC5200' : '#4796F6',
              filter: 'url(#dropshadow)'
            }
          ).addTo(mapRef.current);

          traceLines.push(line);

          bounds.extend(line.getBounds());

          if (traceLines.length === traces.length) {
            callback({traceLines, bounds});
          }
        // }, 5000);



      });
  });
}

export const removeTraceLines = (traceLines) => {
  traceLines?.forEach(l => l.remove());
}



// export const drawTraceLines = (traces, mapRef, callback) => {
//   if (!traces.length) {
//     return;
//   }

//   const traceLines = [];
//   const bounds = L.latLngBounds();

//   traces.forEach(f => {
//     const tracesData = f.path.map(t => ({ lat: t.lat, lng: t.lng }));

//     const line = L.polyline(
//       tracesData,
//       {
//         className: `trace t-${f.trace_uid}`,
//         opacity: 0.7,
//         color: '#FC5200',
//         filter: 'url(#dropshadow)'
//       }
//     ).addTo(mapRef.current);

//     traceLines.push(line);
//     bounds.extend(line.getBounds());
//   });

//   // mapRef.current.fitBounds(bounds, {
//   //   paddingTopLeft: [300, 25],
//   //   paddingBottomRight: [25, 25]
//   // });

//   // mapRef.current.fitBounds(bounds, {
//   //   paddingTopLeft: [0, 0],
//   //   paddingBottomRight: [0, 0]
//   // });

//   // return traceLines;
//   callback({traceLines, bounds});
// }


// export const drawTraceLinesFromUrls = (traceUrls, mapRef, callback) => {
//   // Traces are unique and belong to each flight.
//   // Flight lines not - they're a hypothetical path between airports
//   logger("drawTraceLinesFromUrls:", traceUrls);
//   if (!traceUrls.length) {
//     return;
//   }

//   const traceLines = [];
//   const bounds = L.latLngBounds();

//   traceUrls.forEach(url => {
//     const traceUid = url.split('/').pop().replace('.json', '');

//     axios.get(url)
//       .then(response => {
//         const path = response.data;
//         const tracesData = path.map(t => ({ lat: t[1], lng: t[2] }));

//         const line = L.polyline(
//           tracesData,
//           {
//             className: `trace t-${traceUid}`,
//             opacity: 0.7,
//             color: '#FC5200',
//             filter: 'url(#dropshadow)'
//           }
//         ).addTo(mapRef.current);

//         traceLines.push(line);

//         bounds.extend(line.getBounds());

//         if (traceLines.length === traceUrls.length) {
//           // mapRef.current.fitBounds(bounds, {
//           //   paddingTopLeft: [300, 25],
//           //   paddingBottomRight: [25, 25]
//           // });

//           // mapRef.current.fitBounds(bounds, {
//           //   paddingTopLeft: [10, 10],
//           //   paddingBottomRight: [200, 200]
//           // });
//           callback({traceLines, bounds});
//         }
//       });
//   });
// }
