import * as React from 'react';
import WjCard from 'Lib/WjCard';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FlightMiniMap from './FlightMiniMap';
import TailNumberChip from 'FlightMap/Shared/TailNumberChipHeader';
import { Link } from 'react-router-dom';
import { Avatar, CardHeader, Box } from '@mui/material';
import { formatDate, formatCount, formatHours, formatTime } from 'Lib/format';
import { ChatBubble, ThumbUp, ThumbUpOffAlt } from '@mui/icons-material';
import AirportChips from 'FlightMap/Shared/AirportChips';

export default function FlightPost(props) {
  const { flight } = props;
  const { logbookEntry } = flight;
  const takeoff = flight.getDepartureUTC();
  const instrumentHours = logbookEntry.actualInstrumentHours || 0 + logbookEntry.simulatedInstrumentHours || 0;

  return (
    <WjCard sx={{ m: 1, mb: 2 }}>
      <CardHeader
        avatar={
          flight.profile.profilePhotoUrl ?
            <Avatar src={flight.profile.profilePhotoUrl} />
            :
            <Avatar>
              {flight.profile.getInitials()}
            </Avatar>
        }
        title={flight.profile.getFullName()}
        subheader={<>
          <div>{formatDate(takeoff) + ' at ' + formatTime(takeoff)}</div>
          <TailNumberChip size="large" tailNumber={flight.registrationNumber} />


          <Box sx={{ display: 'flex', fontSize: '1.2rem' }}>
            <div style={{ width: 80, marginRight: 20, borderRight: '1px solid whitesmoke' }}>
              <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Total Hours</Typography><br />
              {formatHours(logbookEntry.totalHours)}h
            </div>

            <div style={{ width: 80, marginRight: 20, borderRight: '1px solid whitesmoke' }}>
              <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Instr. Hours</Typography><br />
              {instrumentHours > 0 ? formatHours(instrumentHours) : 0}h
            </div>

            <div style={{ width: 100 }}>
              <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Landings</Typography><br />
              {formatCount(logbookEntry.dayLandingsCount + logbookEntry.nightLandingsCount)}
            </div>
          </Box>
        </>}
        sx={{ alignItems: 'flex-start' }}
      />

      <CardContent sx={{ p: 0, m: 0 }}>

        <Link to={`/flights/${flight.id}`} style={{ display: 'block', position: 'relative', height: 220, width: '100%', borderRadius: 0, overflow: 'hidden', marginBottom: 12 }}>
          <FlightMiniMap flight={props.flight} />
        </Link>

      </CardContent>


      <CardActions>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ flex: 1 }}>

          </div>
          <div style={{ flex: 1, textAlign: 'right' }}>
            {
              !props.hideLikeButton &&
              <IconButton
                size="small"
                sx={{ mr: 1 }}
              >
                <ThumbUpOffAlt sx={{ fontSize: 18 }} />
              </IconButton>
            }

            {/* <IconButton
              size="small"
            >
              <ChatBubble sx={{ fontSize: 18 }} />
            </IconButton> */}
          </div>
        </div>
      </CardActions>
    </WjCard>
  );
}