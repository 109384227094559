import axios from 'axios';
import ProfileModel from '../Models/ProfileModel';
import { convertKeysToSnakeCase } from '../Lib/convertCase';
import logger from 'Lib/logger';
import FlightStatsModel from 'Models/FlightStatsModel';

// Function to load the pilot profile
export const loadProfile = (authHeaders) => {
  return axios.get('/profiles.json', authHeaders)
    .then(response => {
      const profile = new ProfileModel(response.data.profile || {} );
      logger('Profile loaded:', profile);

      const flightStats =  new FlightStatsModel(response.data.flight_stats || {} );
      logger('Flight stats loaded:', flightStats);

      return { profile, flightStats };
    })
    .catch(error => {
      console.error('Error loading pilot profile:', error);
    });
};

// Function to update the pilot profile
export const updateProfile = (formData, authHeaders) => {

  formData.profileBanner = formData.profileBannerUrl;
  delete formData.profileBannerUrl;

  const {
    medicalExpirationDates,
    profilePhotoUrl,
    flightReviewExpirationDate,
    ...profileData
  } = formData;

  const profile = convertKeysToSnakeCase({
    ...profileData,
    firstName: formData.firstName.trim(),
    lastName: formData.lastName.trim(),
  });



  try {
    return axios.post(
      '/profiles.json',
      { profile },
      authHeaders
    ).then(response => {
      logger('Profile updated:', response.data);
      return new ProfileModel(response.data.profile);
    });

  } catch (error) {
    console.error('Error updating pilot profile:', error);
    throw error;
  }
};

