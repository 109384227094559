import dayjs from "dayjs";
import { toCamelCase, toSnakeCase } from "./convertCase";

export default class BaseModel {
  constructor(data){
    for (const key in data) {
      const camelCaseKey = toCamelCase(key);
      this[camelCaseKey] = data[key];

      // if (key.endsWith('_date') && data[key]) {
      //   this[camelCaseKey] = dayjs(data[key]);
      // }

    }
  }


  asJSON() {
    const snakeCaseData = {};
    for (const key in this) {
      if (this.hasOwnProperty(key)) {
        const snakeCaseKey = toSnakeCase(key);
        snakeCaseData[snakeCaseKey] = this[key];
      }
    }
    return snakeCaseData;
  }

}