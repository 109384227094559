import { DateTime } from "luxon";
import BaseModel from "Lib/BaseModel";
import LogbookEntryModel from "Models/LogbookEntryModel";
import TraceModel from "Models/TraceModel";
import ProfileModel from "Models/ProfileModel";

export default class FlightModel extends BaseModel {
  constructor(data) {
    super(data || {});
    this.logbookEntry = new LogbookEntryModel(this.logbookEntry || {});
    this.traces = (this.traces || []).map(t => new TraceModel(t));
    this.profile = new ProfileModel(this.profile || {});

  }

  static color = '#FC5200';


  getDepartureUTC(){
    const localDateTime = `${this.departureDate}T${this.departureTime || '12:00'}`;
    const dateTimeInTimezone = DateTime.fromISO(localDateTime, { zone: this.departureTzid });
    return dateTimeInTimezone.toJSDate();
  }

}
