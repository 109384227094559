import React, { createContext, useState, useContext } from 'react';
import { Snackbar as MuiSnackbar } from '@mui/material';

export const SnackbarContext = createContext();

export default function Snackbar({ children }){
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const hideSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <MuiSnackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={hideSnackbar}
        message={snackbarMessage}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);