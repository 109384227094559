import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { ListItemButton } from '@mui/material';
import { PersonOutline } from '@mui/icons-material';
import { themeData } from 'theme';
import './NavMenu.css';
import { useAuth } from 'Auth/AuthProvider';
import BottomNav from './BottomNav';

const ResponsiveAppBar = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!isMobile && mobileOpen) {
      setMobileOpen(false);
    }
  }, [isMobile, mobileOpen]);

  const menuId = 'primary-search-account-menu';


  const drawer = (
    <Drawer
      anchor="top"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        style: { top: isPhone ? '56px' : '64px' }, // Adjust the top offset based on AppBar height if necessary
      }}
    >
      <List>
        <NavListItemButton isActive={to => window.location.pathname === '/' } to="/" onClick={handleDrawerToggle}>Home</NavListItemButton>
        <NavListItemButton to="/flights" onClick={handleDrawerToggle}>Flights</NavListItemButton>
        <NavListItemButton to="/logbook" onClick={handleDrawerToggle}>Logbook</NavListItemButton>
        {/* <ListItem dense onClick={handleDrawerToggle}>
        <ListItemText disableTypography>
          <Typography variant="caption" color="textSecondary">Tools</Typography>
        </ListItemText>
        </ListItem> */}

        {/* <NavListItemButton to="/plan" onClick={handleDrawerToggle}>Plan</NavListItemButton>
        <NavListItemButton to="/logbook/page/1" onClick={handleDrawerToggle}>Logbook</NavListItemButton>
        <NavListItemButton to="/crosswind" onClick={handleDrawerToggle}>Crosswind</NavListItemButton> */}
        {user ?
          <>
            <NavListItemButton to="/profile" onClick={handleDrawerToggle}>Profile</NavListItemButton>
            <NavListItemButton to="/sign-out" onClick={handleDrawerToggle}>Sign Out</NavListItemButton>
          </>
          :
          <>
            <NavListItemButton to="/sign-in" onClick={handleDrawerToggle}>Sign In</NavListItemButton>
            <NavListItemButton to="/sign-up" onClick={handleDrawerToggle}>Sign Up</NavListItemButton>
          </>
        }
      </List>
    </Drawer>
  );

  return (
    <div>
      <AppBar elevation={0} position="fixed" style={{ zIndex: theme.zIndex.drawer + 1, flex: 0, backgroundColor: 'white', color: 'inherit', borderBottom: '1px solid #f1f1f1' }}>
        <Toolbar>
          <div style={{ display: 'flex' }}>
            <img src="/wj_orange.png" alt="WingJockey Logo" style={{ height: 40, marginRight: 4 }} />
            <Link className="Nav-brand" to="/" style={{ fontWeight: '500', fontSize: '1.25rem', flexGrow: 1, textDecoration: 'none', lineHeight: '40px' }}>
              WingJockey
            </Link>
          </div>
          {!isMobile && (
            <div style={{ marginLeft: '20px', flexGrow: 1, display: 'flex' }}>
              <NavButton to="/" isActive={to => window.location.pathname === '/' } >Home</NavButton>
              <NavButton to="/flights">Flights</NavButton>
              <NavButton to="/logbook">Logbook</NavButton>
              {/* <ToolsDropdown /> */}
            </div>
          )}
          <div style={{ marginLeft: 'auto' }}>
            {!isMobile && (
              <>
                {user ?
                  <>
                    <NavButton to="/sign-out">Sign Out</NavButton>
                    <IconButton
                      component={Link}
                      to="/profile"
                    >
                      <PersonOutline />
                    </IconButton>
                  </>
                  :
                  <>
                    <NavButton to="/sign-in">Login</NavButton>
                    <NavButton to="/sign-up">Sign Up</NavButton>
                  </>
                }


              </>
            )}
            {isMobile && (
              <IconButton
                edge="end"
                aria-label="open drawer"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleDrawerToggle}
                color="inherit"
              >
                {mobileOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {/* {renderMenu} */}
      {drawer}
      <Toolbar /> {/* This is to offset the content below the AppBar */}

    </div>
  );
};

export default ResponsiveAppBar;



const NavButton = (props) => {
  const pathname = window.location.pathname;
  const isActive = props.isActive ? props.isActive(pathname) : pathname.startsWith(props.to);

  return (
    <Button component={Link} to={props.to} color={isActive ? 'primary' : 'inherit'}>
      {props.children}
    </Button>
  );

};


const NavListItemButton = (props) => {
  const pathname = window.location.pathname;
  const isActive = props.isActive ? props.isActive(pathname) : pathname.startsWith(props.to);

  return (
    <ListItemButton style={{ color: isActive ? themeData.palette.primary.main : 'inherit' }} onClick={props.onClick} component={Link} to={props.to}>
      <ListItemText primary={props.children} />
    </ListItemButton>
  );

};




function ToolsDropdown() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="inherit"
        >
          Tools
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem component={Link} to="/plan">Plan</MenuItem>
                    <MenuItem component={Link} to="/logbook/page/1">Logbook</MenuItem>
                    <MenuItem component={Link} to="/crosswind">Crosswind</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}