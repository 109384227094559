import axios from "axios";
import L from "leaflet";
import logger from "Lib/logger";
import './airports.css';

export const drawAirportsInBounds = (map) => {
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const url = `/airports/in_bounds.json?ne_lat=${ne.lat}&ne_lng=${ne.lng}&sw_lat=${sw.lat}&sw_lng=${sw.lng}`;

  return drawAirports(map, url);
};


export const drawFlightAirports = (map, flight) => {
  const airports = flight.logbookEntry.departureAndArrivalAirports;
  logger("drawFlightAirports", airports);
  const url = `/airports/find_by_ids.json?ids=${airports.join(',')}`;

  return drawAirports(map, url, "primary");
};


export const drawAirports = (map, url, color) => {

  const fetchAndDraw = () => {
    const canvasRenderer = L.canvas();


    // Fetch airports within the map bounds
    axios.get(url)
      .then(response => {
        const {airports} = response.data;
        logger("airports", airports);
        // Clear existing markers
        clearMarkers(map);


        // Add markers for each airport
        airports.forEach((a,i) => {
          // const marker = L.marker([a.lat, a.lng]).addTo(map);
          logger("airport", a);
          const isLast = i === airports.length - 1;

          L.marker(
            [a.lat, a.lng],
            {
              icon: color === "primary" ? getPrimaryAirportMarker(a, isLast) :  getAirportMarkerByTowerType(a),
              renderer: canvasRenderer
            }
          ).addTo(map);
          // addCustomMarker(map, airport.lat, airport.lng, airport.icoa_id || airport.arpt_id);
        });
      })
      .catch((error) => {
        logger("Error fetching airports:", error);
      });
  }

  fetchAndDraw();
};


const clearMarkers = (map) => {
  // Clear existing airport markers from the map
  const markers = document.querySelectorAll('.airport-marker');
  markers.forEach((marker) => {
    map.removeLayer(marker);
  });
  // map.eachLayer(layer => {
  //   if (layer instanceof L.Marker) {
  //     map.removeLayer(layer);
  //   }
  // });
};



export function getAirportMarkerByTowerType(airport) {
  const isUncontrolled = airport.twr_type_code === 'NON-ATCT';
  if(isUncontrolled) {
    return L.divIcon({
      className: 'airport-marker',
      html: `<div class="airport-icon uncontrolled">${ airport.icao_id || airport.arpt_id }</div>`
    });
  }

  return L.divIcon({
    className: 'airport-marker',
    html: `<div class="airport-icon controlled">${ airport.icao_id || airport.arpt_id }</div>`
  });

}

export function getPrimaryAirportMarker(airport, isLast) {
  if(isLast) {
    return L.divIcon({
      className: 'airport-marker',
      html: `<div class="airport-icon primary-last">${ airport.icao_id || airport.arpt_id }</div>`
    });
  }

  return L.divIcon({
    className: 'airport-marker',
    html: `<div class="airport-icon primary">${ airport.icao_id || airport.arpt_id }</div>`
  });

}


