import { useState, useEffect } from "react";
import { Avatar, Grid, LinearProgress, Typography, Container, Box, Button, Card, CardContent, CardHeader } from "@mui/material";
import Nav from "Layout/Layout";
import { Link } from "react-router-dom";
import { useProfile } from "./ProfileProvider";
import { useNavigate } from "react-router-dom";
import WjCard from "Lib/WjCard";
import { useAuth } from "Auth/AuthProvider";
import { getFlights } from "FlightMap/Flights/FlightsApi";
import { formatCount, formatDate, formatHours, shortDateFormat } from "Lib/format";
import logger from "Lib/logger";
import FlightPost from "Dashboard/FlightPost";
import MedicalTracker from "./MedicalTracker";


export default function Profile() {
  document.title = "My Profile | WingJockey";

  const navigate = useNavigate();
  const { isLoadingProfile, profile, flightStats } = useProfile();
  const { authHeaders } = useAuth();

  const [isLoadingFlights, setIsLoadingFlight] = useState(true);
  const [flights, setFlights] = useState([]);


  useEffect(() => {
    getFlights(authHeaders, { limit: 5, offset: 0 })
      .then(data => {
        setFlights(data.flights);
        setIsLoadingFlight(false);
      });
  }, []);


  const flightStatsFlat = flattenObject(flightStats);
  logger('flightStatsFlat', flightStatsFlat);


  return (
    <Nav>
      {
        isLoadingProfile || !profile ? <LinearProgress /> :
        <>
          <Container maxWidth="lg" sx={{ pt: 4, mb: 6 }}>
            <Grid container spacing={1} >

              <Grid item xs={12}>

                <WjCard sx={{ position: 'relative' }}>
                  <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: 175,
                    backgroundColor: 'whitesmoke',
                    backgroundImage: `radial-gradient(transparent, black), url(${ profile.profileBannerUrl || '/sky.jpg' })`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}>
                    <EditButton
                      sx={{
                        display: { xs: 'none', sm: 'block' },
                        color: 'white',
                        top: 12,
                        right: 12,
                        position: 'absolute',
                        borderColor: 'white'
                      }}
                    />
                  </div>

                  <Box sx={{ display: 'flex', pl: 3, pr: 3, mt: 10, mb: 0, justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                    {
                      profile.profilePhotoUrl ?
                        <Avatar src={profile.profilePhotoUrl} sx={{ width: 128, height: 128, border: '3px solid white', }} />
                        :
                        <Avatar sx={{ width: 128, height: 128, fontSize: 48 }}>
                          {profile.getInitials()}
                        </Avatar>
                    }
                  </Box>

                  <CardContent>

                    <Grid container>

                      <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                        <Typography variant="h4" sx={{ mt: 0 }}>
                          {profile.getFullName()}
                        </Typography>
                        <Typography variant="body2">
                          {profile.pilotType}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', textAlign: 'center', mt: 1 }}>
                          <div style={{ flex: 1 }}>
                            <Typography variant="caption" sx={{ fontSize: '0.70rem' }}>Home Airport</Typography>
                            <Typography variant="body1">{profile.homeAirportId}</Typography>
                          </div>

                          <div style={{ flex: 1 }}>
                            <Typography variant="caption" sx={{ fontSize: '0.70rem' }}>Flights</Typography>
                            <Typography variant="body1">{flightStats.flightCount}</Typography>
                          </div>

                          <div style={{ flex: 1 }}>
                            <Typography variant="caption" sx={{ fontSize: '0.70rem' }}>Total Hours</Typography>
                            <Typography variant="body1">{flightStats.totals.totalHours}</Typography>
                          </div>

                        </Box>
                      </Grid>


                      <Grid item xs={12} sx={{ mt: 3, display: { sm: 'none' } }}>
                        <EditButton color="primary" fullWidth />
                      </Grid>

                    </Grid>

                  </CardContent>
                </WjCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <BackgroundHeading sx={{ mt: 1 }}>Stats</BackgroundHeading>

                <WjCard sx={{ mt: 1 }}>
                  <CardContent>
                    <Typography component="div" variant="caption" sx={{ mb: 1, fontSize: '0.65rem' }}>Totals</Typography>
                    {
                      totalStats.map(stat => (
                        <Stat
                          key={stat.label}
                          label={stat.label}
                          metric={flightStatsFlat[stat.key]}
                          format={stat.format}
                        />
                      ))
                    }

                    <Typography component="div" variant="caption" sx={{ mb: 1, mt: 3, fontSize: '0.65rem' }}>Currency</Typography>
                    {
                      currencyStats.map(stat => (
                        <Stat
                          key={stat.label}
                          label={stat.label}
                          metric={flightStatsFlat[stat.key]}
                          format={stat.format}
                        />
                      ))
                    }

                    <Typography component="div" variant="caption" sx={{ mb: 1, mt: 3, fontSize: '0.65rem' }}>Progress</Typography>
                    {
                      progressStats.map(stat => (
                        <Stat
                          key={stat.label}
                          label={stat.label}
                          metric={flightStatsFlat[stat.key]}
                          format={stat.format}
                        />
                      ))
                    }


                    <Typography component="div" variant="caption" sx={{ mb: 1, mt: 3, fontSize: '0.65rem' }}>Instrument Progress</Typography>
                    {
                      instrumentProgress.map(stat => (
                        <Requirement
                          key={stat.label}
                          label={stat.label}
                          required={stat.required}
                          metric={flightStatsFlat[stat.key]}
                          format={stat.format}
                        />
                      ))
                    }



                  </CardContent>
                </WjCard>


                <BackgroundHeading sx={{ mt: 3 }}>Flight Review</BackgroundHeading>

                <WjCard sx={{ mt: 1, p: 2 }}>

                  <Stat label="Last Checkride or Review" metric={profile.flightReviewDate || profile.privatePilotDate} format={shortDateFormat} />

                  <Stat label="Flight Review Due" metric={profile.flightReviewExpirationDate} format={shortDateFormat} />

                  <Stat label="Flight Review Days" metric={profile.getFlightReviewRemainingDays()} format={formatCount} />

                </WjCard>

                <BackgroundHeading sx={{ mt: 3 }}>Medical</BackgroundHeading>
                <MedicalTracker profile={profile} />

              </Grid>




              <Grid item xs={12} md={8}>
                <BackgroundHeading sx={{ mt: 1, ml: 1 }}>Recent Flights</BackgroundHeading>
                { flights.map((flight) => (
                  <FlightPost key={flight.id} flight={flight} hideLikeButton />
                ))}
              </Grid>

            </Grid>


          </Container>
        </>
      }
    </Nav>
  )

}



function BackgroundHeading(props) {
  return (
    <Typography
      variant="body1"
      sx={{ color: '#999', fontSize: '0.75rem', fontWeight: 'bold', ...props.sx }}
    >
      {props.children}
    </Typography>
  )
}

function EditButton(props) {
  return (
    <Button component={Link} to="/profile/edit" variant="outlined" {...props}>
      Edit Profile
    </Button>
  );
}

export function Stat({ label, metric, format }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 0.5 }}>
      <Typography component="div" sx={{ flex: 2 }} variant="body2">
        {label}
      </Typography>
      <Typography component="div" sx={{ flex: 1, textAlign: 'right' }} variant="body2">
        {format ? format(metric) : metric}
      </Typography>
    </Box>
  );
}



export function Requirement({ label, metric, format, required }) {
  const remaining = Math.max(required - metric, 0);
  return (
    <Box sx={{ mb: 0.5 }}>
       <Typography component="div" sx={{ flex: 2 }} variant="body2">
          {label}
        </Typography>
      <Box  sx={{ display: 'flex', flexDirection: 'row', mb: 0.5 }}>
        <Typography component="div" sx={{ flex: 1, textAlign: 'left' }} variant="body2">
          {format(remaining)}
        </Typography>
        <Typography component="div" sx={{ flex: 1, textAlign: 'right' }} variant="body2">
          {format ? format(metric) : metric } / {formatCount(required)}
        </Typography>
      </Box>
      <div>
      <LinearProgress variant="determinate" value={100 - (remaining / required * 100)} />

      </div>
    </Box>
  );
}


const totalStats = [
  { label: 'Flights', key: 'flightCount', format: formatCount },
  { label: 'Hours', key: 'totals.totalHours', format: formatHours },
  { label: 'Landings', key: 'totals.totalLandingsCount', format: formatCount },
  { label: 'Approaches', key: 'totals.instrumentApproachesCount', format: formatCount },
];

const currencyStats = [
  { label: 'Landings (90d)', key: 'totals.currentSelLandingsCount', format: formatCount },
  { label: 'Night Landings (90d)', key: 'totals.currentSelNightLandingsCount', format: formatCount },
  { label: 'Approaches (6m)', key: 'totals.currentInstrumentApproachesCount', format: formatCount },
];

const progressStats = [
  { label: 'Solo', key: 'totals.soloHours', format: formatHours },
  { label: 'Cross Country', key: 'totals.crossCountryHours', format: formatHours },
  { label: 'Pilot-in-Command', key: 'totals.pilotInCommandHours', format: formatHours },
  { label: 'Cross Country PIC', key: 'totals.pilotInCommandCrossCountryHours', format: formatHours },
  { label: 'Instrument', key: 'totals.totalInstrumentHours', format: formatHours },
  { label: 'Dual Instrument', key: 'totals.dualInstrumentHours', format: formatHours },
];


const instrumentProgress = [
  { label: 'Cross Country PIC', key: 'totals.pilotInCommandCrossCountryHours', format: formatHours, required: 50 },
  { label: 'Instrument', key: 'totals.totalInstrumentHours', format: formatHours, required: 40 },
  { label: 'Dual Instrument', key: 'totals.dualInstrumentHours', format: formatHours, required: 15 },
];




function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}