import { FlightTakeoff } from '@mui/icons-material';
import { Card, CardHeader, CardContent, Button, Typography, IconButton, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

export default function SignUpCard(props) {
  return (
    <Card elevation={0} sx={{ position: 'sticky', top: 0, m: 1, overflow: 'inherit', mt: 6 }}>
      <CardHeader
        title="Get Exclusive Access"
        sx={{ pl: 0, pr: 0, pt: 4, pb: 0, textAlign: 'center' }}
      />

      <CardContent>
        <div>
          <Typography component="div" variant="caption" color="primary" sx={{ fontWeight: 'bold' }}>
            Boarding pass required.
          </Typography>
          <Typography component="div" variant="caption">
            WingJockey is invite-only, ensuring a curated experience for pilots like you.
          </Typography>
        </div>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Button
          component={Link}
          to="/request-an-invite"
          variant="outlined"
          fullWidth
          size="small"
          startIcon={<FlightTakeoff />}
        >
          Request Invite
        </Button>

      </CardContent>

    </Card>
  )
}