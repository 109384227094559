import React, { useState } from 'react';
import axios from 'axios';
import { Button, Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useAuth } from 'Auth/AuthProvider';
import logger from 'Lib/logger';
import { useSnackbar } from 'Lib/Snackbar';

const Input = styled('input')({
  display: 'none',
});

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: -24,
  right:-24,
  height: 175,
  backgroundColor: 'whitesmoke',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  cursor: 'pointer',
  '&:hover, &:focus': {
    cursor: 'pointer',
    '&::after': {
      fontSize: 12,
      content: '"Choose a banner"',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1),
      width: '100%',
      height: '100%',
    },
    '&::after > svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

const ProfileBannerUpload = (props) => {
  const { authHeaders } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [profileBanner, setProfileBanner] = useState(null);
  const [preview, setPreview] = useState(null);


  const handleProfileBannerChange = (e) => {
    const file = e.target.files[0];
    setProfileBanner(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('profile_banner', profileBanner);
    logger("authHeaders", authHeaders)

    try {
      const response = await axios.patch('/profiles/update_profile_banner', formData, {
        headers: {
          ...authHeaders.headers,
          'Content-Type': 'multipart/form-data',
        },
      });

      logger(response.data);

      showSnackbar('Photo updated')
      props.onUpdate(response.data.profile_banner_url);

    } catch (error) {
      console.error('Error uploading profile banner:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginBottom: 24, position: 'relative', height: 175}}>
      <label htmlFor="profileBanner">
        <Input
          accept="image/*"
          id="profileBanner"
          type="file"
          onChange={handleProfileBannerChange}
        />
         <StyledBox
          style={{
  backgroundImage: `radial-gradient(transparent, black), url(${preview ? preview : (props.profileBannerUrl ? props.profileBannerUrl : '/sky.jpg')})`,

          }}>
            <Button
              size="small"
              type="submit"
              variant="outlined"
              disabled={!profileBanner}
              sx={{
                color: 'white',
                top: 12,
                right: 18,
                position: 'absolute',
                borderColor: 'white',
                zIndex: 1000
              }}>
              Update Banner
            </Button>
            {!preview && <CameraAltIcon />}

          </StyledBox>

      </label>


    </form>
  );
};

export default ProfileBannerUpload;