import axios from 'axios';
import logger from 'Lib/logger';

axios.interceptors.response?.use(undefined, error => {

  return new Promise((resolve, reject) => {

    // const errorState = {
    //   errorStatus: error?.response?.status,
    //   errorMessage: error?.response?.message,
    // }

    const isSignIn = window.location.pathname.includes('/sign-in');

    console.log('error', error);

    if(error?.response?.status === 401 && !isSignIn){
      // window.location.href = '/sign-in';
      return reject(error);
    }

    if(error?.response?.status === 403){
      logger( error );
    }

    if(error?.response?.status === 503){
      // window.location.href = `/`;

      return reject(error);
    }

    throw error;

  });

});