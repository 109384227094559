import { useEffect, useState, createContext } from 'react';
import { Box, LinearProgress, Button, Avatar, IconButton, Toolbar, CardHeader, Chip, Typography } from '@mui/material';
import { useAuth } from 'Auth/AuthProvider';
import { Edit, FlightLand, FlightTakeoff, Route } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getFlight, getTraceByFlight } from 'FlightMap/Flights/FlightsApi';
import { getTracePath } from 'FlightMap/TraceDetail/TracesApi';
import { useFlightMap } from 'FlightMap/FlightMapProvider';
// import CardHeader from 'FlightMap/Shared/CardHeader';
import { Section } from 'FlightMap/Flights/FlightForm';
import LogbookEntryModel from 'Models/LogbookEntryModel';
import AirportChips from 'FlightMap/Shared/AirportChips';
import axios from 'axios';
// import TraceChart from 'FlightMap/TraceDetail/TraceChart';
import logger from 'Lib/logger';
import FlightModel from 'Models/FlightModel';
import FlightMiniMap from 'Dashboard/FlightMiniMap';
import Layout from 'Layout/Layout';
// import BaseMap from 'BaseMap/BaseMap';
import TailNumberChip from 'FlightMap/Shared/TailNumberChipHeader';
import BaseMap from 'BaseMap/BaseMap';
import { formatDate, formatCount, formatHours, formatTime } from 'Lib/format';

import TraceChart from 'FlightMap/TraceDetail/TraceChart';
import FlightPhotoUpload from './FlightPhotoUpload';
import FlightPhotos from './FlightPhotos';
import AudioTimeline from './AudioTimeline';
import { getAudio } from './AudioApi';
import AudioUpload from './FlightAudioUpload';


/*
  This component should do a few things. It should show the details of the trace / trace.
  If the user is not logged in or the flight isn't logged, it should show the trace details.
  These details may be copied to the flight itself
  If the user is logged in - it should show the details of the flight if it is logged.
*/



export default function ViewFlight(props) {
  const params = useParams();
  const { setMapFlights, setMapTraces } = useFlightMap();
  const { authHeaders, isSignedIn } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [trace, setTrace] = useState(null);
  const [tracePath, setTracePath] = useState(null);
  const [flight, setFlight] = useState(null);
  const [audioSnippets, setAudioSnippets] = useState(null);

  useEffect(() => {
    console.log('flightId:', params.flightId);
    setMapFlights([]);
    setMapTraces([]);
    setLoading(true);


    getFlight(params.flightId, authHeaders)
      .then(flight => {

        setFlight(flight);
        setLoading(false);


        if (!flight.traces.length) {
          setMapFlights([flight]);
          return
        }

        console.log('traces', flight.traces);
        setMapTraces(flight.traces);
        const tracePathRequests = flight.traces.map(t => getTracePath(t));
        axios.all(tracePathRequests)
          .then(responses => {
            const sortedPaths = responses.sort((a, b) => a[0].t - b[0].t);
            const tracePath = sortedPaths.reduce((a, b) => a.concat(b), []);
            logger('tracePath', tracePath);
            setTracePath(tracePath);
          })

      });


      getAudio(authHeaders)
      .then(({ audioSnippets }) => {
        setAudioSnippets(audioSnippets);
      })

    // getTraceByFlight(params.flightId, authHeaders)
    //   .then(traces => {
    //     if(!traces.length) return;

    //     const traceUrls = traces.map(t => t.getUrl() );
    //     setMapTraces(traceUrls);
    //   })
  }, [params.flightId]);




  if (loading) {
    return <LinearProgress />
  }


  const { logbookEntry } = flight;
  const takeoff = flight.getDepartureUTC();
  const instrumentHours = logbookEntry.actualInstrumentHours || 0 + logbookEntry.simulatedInstrumentHours || 0;

  const registrationNumber = params.flightId.split('-')[0];


  return (

    <Layout disableGutters>
      <div style={{ display: 'flex', height: '100%' }}>

        <Box style={{ flex: 1, backgroundColor: 'white', maxWidth: '500px' }} sx={{ p: 2 }}>

          <CardHeader
            avatar={
              flight.profile.profilePhotoUrl ?
                <Avatar src={flight.profile.profilePhotoUrl} />
                :
                <Avatar>
                  {flight.profile.getInitials()}
                </Avatar>
            }
            title={flight.profile.getFullName()}
            subheader={<>
              <Typography variant='caption'>{formatDate(takeoff) + ' at ' + formatTime(takeoff)}</Typography>
            </>}
            sx={{ alignItems: 'flex-start', p: 0 }}
          />

          {/* <TailNumberChip size="large" tailNumber={flight.registrationNumber} /> */}
          <AirportChips airports={logbookEntry.departureAndArrivalAirports} />


          <Box sx={{ display: 'flex', fontSize: '1.2rem' }}>
            <div style={{ width: 80, marginRight: 20, borderRight: '1px solid whitesmoke' }}>
              <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Total Hours</Typography><br />
              {formatHours(logbookEntry.totalHours)}h
            </div>

            <div style={{ width: 80, marginRight: 20, borderRight: '1px solid whitesmoke' }}>
              <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Instr. Hours</Typography><br />
              {instrumentHours > 0 ? formatHours(instrumentHours) : 0}h
            </div>

            <div style={{ width: 100 }}>
              <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Landings</Typography><br />
              {formatCount(logbookEntry.dayLandingsCount + logbookEntry.nightLandingsCount)}
            </div>
          </Box>

          <Box>
            <Section label="Logbook Details">
              <div>
                <Link to={`/flights/${params.flightId}/edit`}>
                  <IconButton size="small">
                    <Edit />
                  </IconButton>
                </Link>
              </div>
              {
                LogbookEntryModel.hoursFields.map(f => {
                  if (!logbookEntry[f.field]) return null;
                  return (
                    <Typography key={f.field} component="div" variant="caption" sx={{ fontSize: '0.8rem' }}>
                      {f.displayName}: {logbookEntry[f.field]}
                    </Typography>
                  )
                })
              }
            </Section>
          </Box>

          <Section label="Photos">
            <FlightPhotos photoUrls={flight.photoUrls || []} />
            <FlightPhotoUpload flightId={flight.id}/>
          </Section>

          <Section label="Debrief">
          </Section>

          <Section label="Notes">
          </Section>


        </Box>

        <Box style={{ position: 'relative', flex: 3, height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ position: 'relative', flex: 1 }}>
              {/* <FlightMiniMap flight={flight} /> */}
              <BaseMap />
            </div>
            <div style={{ flex: 1, maxHeight: '280px', width: '100%', height: '100%' }}>

              {tracePath &&
                <Box>
                  <TraceChart tracePath={tracePath} color={FlightModel.color} />
                </Box >
              }

<AudioUpload />

          {
            audioSnippets &&  <AudioTimeline audioSnippets={audioSnippets} />
          }


              {/* <Typography variant="caption" >
                Upload Audio
              </Typography> */}
            </div>
          </div>
        </Box>

      </div>
    </Layout>
  )
}


// //  <CardHeader
// showBack
// label="Logged Flights"
// backTo="/flights"
// toolbar={
//   <>
//     {/* {
//       flight && flight.traces.length === 0 &&
//       <IconButton onClick={() => navigate(`/flights/${params.flightId}/traces`)}>
//         <Route />
//       </IconButton>
//     } */}
//     <IconButton onClick={() => navigate(`/flights/${params.flightId}/edit`)}>
//       <Edit />
//     </IconButton>
//   </>
// }
// >
{/* <Box sx={{ flex: 1 }}>
  <Chip
    label={registrationNumber}
    size="large"
    sx={{
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 0,
      fontWeight: 'bold',
      fontSize: 18,
      margin: '4px 8px'
    }}
  />
</Box> */}
// </CardHeader>
// {
// loading ? <LinearProgress /> : <Divider />
// }
// {
// !loading &&
// <>
//   <Box sx={{ p: 1 }}>
//     <Typography component="div" variant="body1" sx={{ fontWeight: 'bold', display: 'flex' }}>
//       <div style={{ flex: 1 }}>
//         {formatDate(flight.getDepartureUTC())}
//       </div>
//       <div style={{ textAlign: 'right' }}>
//         {formatHours(logbookEntry.totalHours)}h
//       </div>
//     </Typography>
//     <Typography component="div" variant="caption" sx={{ display: 'flex' }}>
//       <div style={{ flex: 1 }}>
//         {formatTime(flight.getDepartureUTC())}
//       </div>
//       <div style={{ textAlign: 'right' }}>
//       </div>
//     </Typography>
//   </Box>

//   <AirportChips airports={logbookEntry.departureAndArrivalAirports} />

// {tracePath &&
//     <Box>
//       <TraceChart tracePath={tracePath} color={FlightModel.color} />
//     </Box >
//   }


