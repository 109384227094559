import { Box, Icon, Toolbar, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';

export default function CardHeader(props) {
  return (
    <Box sx={{ p: 1, display: 'flex', height: 56 }}>
      {
        props.backTo && <div style={{ alignContent: 'center' }}>
          <IconButton size="small" component={Link} to={props.backTo}>
            <ArrowBackIos/>
          </IconButton>
        </div>
      }

      {
        props.title ?
        <h4 style={{ flex: 1, margin: '8px 6px', fontSize: '18px' }}>
          {props.title}
        </h4>
         :
        <>{ props.children }</>
      }

      <Toolbar variant="dense" disableGutters sx={{ minHeight: 36 }}>
       {props.toolbar}
      </Toolbar>
    </Box>
  )
}