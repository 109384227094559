import './LogbookTable.css';
import { Flight, NoteAdd } from '@mui/icons-material';
import { formatCount, formatHours } from 'Lib/format';
import { Link } from 'react-router-dom';
import { LOGBOOK_ENTRIES_PER_PAGE } from './LogbookApi';
import { styled, alpha } from '@mui/material/styles';
import { sum } from 'd3';
import { TableContainer, Button, Box, Typography, Toolbar } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import Cell from './Cell';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FlightModel from 'Models/FlightModel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogbookRow from './LogbookRow';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useState, useEffect } from 'react';


const FlightLogTable = (props) => {
  const [flights, setFlights] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    console.log('FlightLogTable Setting Flights', props.flights);
    setFlights([...props.flights]);
  }, [props.flights]);

  const handleFlightChange = (rowIndex, flight) => {
    const newFlights = [...flights];
    newFlights[rowIndex] = flight;
    setFlights(newFlights);
  };

  const handleAddRow = () => {
    if (flights.length < LOGBOOK_ENTRIES_PER_PAGE) {
      setFlights([...flights, { ...new FlightModel() }]);
    }
  };

  const onSaving = () => {
    setIsSyncing(true);
  }

  const onComplete = () => {
    setIsSyncing(false);
  }

  return (
    <div>
      <Toolbar disableGutters variant="dense">
        <>
          <div>
            {
              props.showAddRow &&
              <Button
                size="small"
                onClick={handleAddRow}
                variant="contained"
                startIcon={<Flight />}
                disabled={flights.length >= LOGBOOK_ENTRIES_PER_PAGE}
              >
                New Flight
              </Button>
            }

            {
              props.toolbar
            }
          </div>

          <div>
            {
              isSyncing &&
              <>
                <Typography variant="caption" color="GrayText" component="div">
                  Syncing
                </Typography>
              </>

            }
          </div>

        </>
      </Toolbar>

      {/* <Box sx={{ p: 1 }}>
        <Typography variant="caption" color="GrayText" component="div">
            {
              flights.length >= LOGBOOK_ENTRIES_PER_PAGE ?
              `Page full - log flights on another page.`
              : <>&nbsp;</>
            }
        </Typography>
      </Box> */}

      <TableContainer
        component="form"
        sx={{
          overflow: 'scroll',
          backgroundColor: 'white',
          borderLeft: '1px solid #ddd',
          borderRight: '1px solid #ddd',
          scrollbarGutter: 'stable',
          scrollbarWidth: 'thin',
          borderRadius: 2
        }}
      >
        <table className="LogbookTable">
          <thead>
            <tr className='LogbookTable-Row'>
              <th rowSpan={2} style={{ textAlign: 'center' }}>FLIGHT</th>
              <th style={{ width: 100 }} rowSpan={2}>DATE</th>
              <th style={{ width: 100 }} rowSpan={2}>TIME (LOCAL)</th>
              <th rowSpan={2}>AIRCRAFT<br />MAKE and<br />MODEL</th>
              <th rowSpan={2}>AIRCRAFT<br />IDENTIFICATION<br />NUMBER</th>
              <th rowSpan={2}>POINTS OF DEPARTURE &<br />ARRIVAL</th>
              <th rowSpan={1} colSpan={2}>AIRCRAFT CATEGORY and CLASSIFICATION</th>
              <th rowSpan={1} colSpan={4}>TYPE OF PILOTING TIME</th>
              <th rowSpan={2}>GROUND<br />TRAINER</th>
              <th rowSpan={1} colSpan={5}>CONDITIONS OF FLIGHT</th>
              <th rowSpan={2}>NO.<br />INSTR.<br />APPR.</th>
              <th rowSpan={1} colSpan={2}>NO.<br />LDG.</th>
              <th rowSpan={2}>TOTAL<br />DURATION<br />OF FLIGHT</th>
              <th rowSpan={2}>REMARKS<br /><small>PROCEDURES, MANEUVERS, ENDORESEMENTS</small></th>
            </tr>
            <tr>
              <th>
                AIRPLANE<br />SINGLE ENGINE<br />LAND
              </th>
              <th>AIRPLANE<br />MULTI-ENGINE<br />LAND</th>

              <th>DUAL<br />RECEIVED</th>
              <th>PILOT-IN-<br />COMAND</th>
              <th>SECOND-IN-<br />COMAND</th>
              <th>AS FLIGHT<br />INSTRUCTOR</th>


              <th>DAY</th>
              <th>NIGHT</th>
              <th>CROSS-<br />COUNTRY</th>
              <th>ACTUAL<br />INSTRUMENT</th>
              <th>SIMULATED<br />INSTRUMENT</th>

              <th>DAY</th>
              <th>NIGHT</th>
            </tr>
          </thead>
          <tbody>

            {
              flights.map((flight, rowIndex) => (
                <LogbookRow
                  flight={flight}
                  key={flight.id || rowIndex}
                  rowIndex={rowIndex}
                  onChange={handleFlightChange}
                  onSaving={onSaving}
                  onComplete={onComplete}
                />
              ))
            }

            {
              flights.length === 0 &&
              <tr>
                <td colSpan={23} style={{ padding: 6, }}>
                  <div>
                    <Typography variant="caption">No flights logged yet.</Typography>
                  </div>
                </td>
              </tr>
            }
          </tbody>
          <tbody>
            {
              Array.from({ length: LOGBOOK_ENTRIES_PER_PAGE - flights.length }).map((_, i) => (
                <tr key={i}>
                  <td colSpan={23} style={{ padding: 14, backgroundColor: 'white' }} />
                </tr>
              ))
            }
            <tr>
              <td colSpan={23}>
                <div style={{ borderBottom: '1px solid black' }} />
              </td>
            </tr>
          </tbody>
          <tbody className="LogbookTable-pageTotals">

            <tr>
              <td colSpan={6} style={{ textAlign: 'right', paddingRight: 6 }}>
                PAGE TOTAL
              </td>
              <TotalCell flights={flights} hoursKey="airplaneSingleEngineLandHours" />
              <TotalCell flights={flights} hoursKey="airplaneMultiEngineLandHours" />
              <TotalCell flights={flights} hoursKey="dualReceivedHours" />
              <TotalCell flights={flights} hoursKey="pilotInCommandHours" />
              <TotalCell flights={flights} hoursKey="secondInCommandHours" />
              <TotalCell flights={flights} hoursKey="flightInstructorHours" />
              <TotalCell flights={flights} hoursKey="groundTrainerHours" />
              <TotalCell flights={flights} hoursKey="dayHours" />
              <TotalCell flights={flights} hoursKey="nightHours" />
              <TotalCell flights={flights} hoursKey="crossCountryHours" />
              <TotalCell flights={flights} hoursKey="actualInstrumentHours" />
              <TotalCell flights={flights} hoursKey="simulatedInstrumentHours" />
              <TotalCell flights={flights} hoursKey="instrumentApproachesCount" format={formatCount} />
              <TotalCell flights={flights} hoursKey="dayLandingsCount" format={formatCount} />
              <TotalCell flights={flights} hoursKey="nightLandingsCount" format={formatCount} />
              <TotalCell flights={flights} hoursKey="totalHours" />
              <td></td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};

export default FlightLogTable;



function TotalCell(props) {
  const formatType = props.format || formatHours;

  const t = sum(props.flights, f => f.logbookEntry[props.hoursKey])
  return (
    <td>{t ? formatType(t) : null}</td>
  );
}





const CopyFromMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));




function MenuItems() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      <CopyFromMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem>
      </CopyFromMenu>
    </div>
  );
}
