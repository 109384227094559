import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import logger from 'Lib/logger';
import { LinearProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';


export const AuthContext = createContext();

const TOKEN_KEY = 'jwt';

export default function AuthProvider({children}){
  const navigate = useNavigate();

  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [user, setUser] = useState(null);
  const [authHeaders, setAuthHeaders] = useState(null);


  useEffect(() => {
    const checkAuth = () => {
      logger("Checking auth...");

      const token = localStorage.getItem(TOKEN_KEY);

      if (!token) {
        logger('No token found');
        setIsCheckingAuth(false);
        return;
      }

      return axios.get('/auth/check.json', {
        headers: { 'Authorization': `Bearer ${token}`},
      })
      .then(response => {
        logger('response', response);

        if (response.status === 200 && response.data.user) {
          const { user } = response.data;
          logger('Setting user...', user);
          setAuthHeaders({ headers: { 'Authorization': `Bearer ${token}` }});
          setUser(user);
        }
        else {
          logger('Unsetting user:', response.status);
          localStorage.removeItem(TOKEN_KEY);
          setUser(null);
          setAuthHeaders(null);
          navigate('/sign-in');
        }

        setIsCheckingAuth(false);
      })
      .catch(error => {
        logger('Error checking auth:', error);
        console.error('Error checking auth:', error);
        setUser(null);
        setAuthHeaders(null);
      })

    };

    checkAuth();
  }, []);


  const signIn = (email, password) => {
    logger('Signing in...', email);

    return axios.post(`/users/sign_in.json`, {
      user: { email, password }
    })
    .then(response => {
      logger('Signed in:', response);
      const { user } = response.data;
      setUser(user);

      const authorizationHeader = response.headers.authorization;
      if (authorizationHeader) {
        parseAndSetToken(authorizationHeader);
      }
      return user;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logger('Error signing in:', error);
        // Handle any errors that occur during the sign-in process
        setIsCheckingAuth(false);
      } else {
        throw error;
      }
    });
  };


  const parseAndSetToken = (authorizationHeader) => {
    const token = authorizationHeader.split(' ')[1];
    logger('Setting token...', token);
    localStorage.setItem(TOKEN_KEY, token);
    setAuthHeaders({ headers: { 'Authorization': `Bearer ${token}` }});
  };

  const signOut = () => {
    const token = localStorage.getItem(TOKEN_KEY);
    return axios.delete(`/users/sign_out.json`, authHeaders)
    .finally(() => {
      setUser(null);
      setAuthHeaders(null);
      localStorage.removeItem(TOKEN_KEY);
    });
  };

  const isSignedIn = user ? true : false;

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, authHeaders, isSignedIn }}>
      { isCheckingAuth ? <LinearProgress/> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);