import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import axios from 'axios';

const AudioUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('audio[files][]', selectedFiles[i]);
    }

    try {
      const response = await axios.post('/audios', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      alert('Files uploaded successfully!');
    } catch (error) {
      console.error('There was an error uploading the files!', error);
      alert('Failed to upload files');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6">Upload MP3 Files</Typography>
      <input
        accept="audio/mp3"
        style={{ display: 'none' }}
        id="upload-mp3-files"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-mp3-files">
        <Button variant="contained" component="span">
          Choose Files
        </Button>
      </label>
      {selectedFiles.length > 0 && (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {selectedFiles.length} files selected
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        onClick={handleUpload}
        disabled={selectedFiles.length === 0}
      >
        Upload
      </Button>
    </Box>
  );
};

export default AudioUpload;