// SignUp.js
import React, { useState } from 'react';
import { register } from './AuthApi';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useAuth } from './AuthProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'Lib/Snackbar';

const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();
  const { showSnackbar } = useSnackbar();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      alert('Passwords do not match');
      return;
    }
    try {
      setIsLoading(true);
      register(email, password, passwordConfirmation)
        .then(resp => {
          if (resp.data.id) {
            signIn(email, password)
              .then(user => {
                if (user) {
                  navigate('/profile/edit', { replace: true });
                } else {
                  setIsLoading(false);
                  showSnackbar('Could not log in', { severity: 'error' });
                }
              });
          } else {
            setIsLoading(false);
            showSnackbar('Could not sign up.', { severity: 'error' });
            return;
          }
        });
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            elevation={0}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordConfirmation"
            label="Confirm Password"
            type="password"
            id="passwordConfirmation"
            autoComplete="new-password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <LoadingButton
            loading={isLoading}
            loadingIndicator="Signing Up…"
            type="submit"
            fullWidth
            variant="contained"
            elevation={0}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </LoadingButton>
          <Button
            component={Link}
            to="/sign-in"
            fullWidth
            variant="text"
            sx={{ mt: 1, mb: 1 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUp;