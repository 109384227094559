import { IconButton, Menu, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import { MapContainer, TileLayer, SVGOverlay } from "react-leaflet";
import { useAuth } from "Auth/AuthProvider";
import { useFlightMap } from "FlightMap/FlightMapProvider";
// import { useFlightMap } from "FlightMap/FlightMap";
import { useState, useEffect, useContext } from "react";
import L from "leaflet";
import LayersIcon from '@mui/icons-material/Layers';
import logger from "Lib/logger";
import './BaseMap.css';
import { drawFlightCurves, removeFlightCurves } from './flightCurves';
import { drawTraces, removeTraceLines } from './traceLines';
import { getFlightSegmentsAndAirports } from './BaseMapApi';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { drawAirportsInBounds } from './airports';
import * as protomapsL from 'protomaps-leaflet';

import airplaneIcon from './airplaneIcon';
import themes from './mapThemes';




export default function BaseMap({ children }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  const { authHeaders } = useAuth();
  const { mapRef, mapTraces, mapFlights, setBrushMarker, brushMarker } = useFlightMap();

  // Layers
  const [vfrLayerVisible, setVfrLayerVisible] = useState(false);
  const [ifrLayerVisible, setIfrLayerVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);



  // Traces
  const [traceLines, setTraceLines] = useState([]);
  // Flight Segments and Airports
  const [flightCurves, setFlightCurves] = useState([]);
  const [flightSegmentsAndAirports, setFlightSegmentsAndAirports] = useState({
    flightSegments: [],
    airports: []
  });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  let labelRules = [
    {
        dataLayer: "places",
        symbolizer: new protomapsL.CenteredTextSymbolizer({
            label_props:["name:en"],
            fill:"#555", // dark
            stroke: "black", // dark
            width: 2,
            // fill:"#777", // light
            // stroke: "white", // light
            // font:(z,f) => {
            //     if (f.props["pmap:kind"] === "country") return "600 16px sans-serif"
            //     else if (f.props["pmap:kind"] === "locality") return "400 14px sans-serif"
            //     return "800 0px sans-serif"
            // }
        })
    }
]




  useEffect(() => {

    if (!mapRef.current) return;
    const map = mapRef.current;

    const baseLayer = protomapsL.leafletLayer({
      url: 'https://storage.googleapis.com/deadstick-public/proto/20240729.pmtiles',
      labelRules,
      paintRules: protomapsL.paintRules(themes.dark)
    });

    baseLayer.addTo(map);

    //   map.on("click", (ev) => {
    //     console.log(ev.latlng)
    //     if(!ev.latlng) return;
    //     const wrapped = map.wrapLatLng(ev.latlng);
    //     console.log(baseLayer.queryTileFeaturesDebug(wrapped.lng, wrapped.lat));
    // })

    // const usBounds = [
    //   [24.396308, -125.0],  // Southwest corner
    //   [49.384358, -66.93457] // Northeast corner
    // ];

    // setTimeout(() => {
    //   if (!map) return;
    //   // setMapBounds(map.getBounds());
    //   map.fitBounds(usBounds, {
    //     padding: [50, 50],  // Add padding to the fit
    //     maxZoom: 5          // Limit maximum zoom level
    //   });

    // }, 1500);

    const marker = L.marker([0, 0], { icon: airplaneIcon }).addTo(map);
    setBrushMarker(marker);
  }, [mapRef.current]);



  const resetMap = () => {
    removeTraceLines(traceLines);
    removeFlightCurves(flightCurves);
    brushMarker?.setLatLng([0, 0]);
  };


  useEffect(() => {
    resetMap();
    drawTraces(mapTraces, mapRef, (data) => {
      setTraceLines(data.traceLines);

      if(!mapRef || !mapRef.current) return;

      mapRef.current.fitBounds(data.bounds, getPaddingAndZoom(isPhone));
    });
  }, [mapRef, mapTraces]);


  useEffect(() => {
    console.log('BaseMap useEffect mapFlights', mapFlights);
    getFlightSegmentsAndAirports(mapFlights)
      .then(({ flightSegments, airports }) => {
        setFlightSegmentsAndAirports({ flightSegments, airports });
      });

  }, [mapRef, mapFlights]);


  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    resetMap();

    drawFlightCurves(mapRef.current, flightSegmentsAndAirports, data => {

      setFlightCurves(data.flightCurves);

      try {
        mapRef.current?.fitBounds(data.bounds, getPaddingAndZoom(isPhone));
      }
      catch(e) {
        console.log('Error fitting bounds', e);
      }
    });

  }, [mapRef.current, flightSegmentsAndAirports]);

  // useEffect(() => {
  //   const map = mapRef.current;
  //   if (!map) return;
  //   drawAirportsInBounds(map);
  // }, [mapRef.current]);




  return (
    <>
      <MapContainer
        ref={mapRef}

        center={[37.94,-95.967]}
        zoom={4}
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        zoomControl={false}
      >
        {vfrLayerVisible &&
          <TileLayer
            url="https://storage.googleapis.com/deadstick-public/us_tiles/{z}/{x}/{y}.webp"
            tms={true}
            updateWhenZooming={false}
            updateWhenIdle={true}
            detectRetina={true}
          />
        }

        {ifrLayerVisible &&
          <TileLayer
            url="https://storage.googleapis.com/deadstick-public/US_IFR_Tiles/{z}/{x}/{y}.webp"
            tms={true}
            updateWhenZooming={false}
            updateWhenIdle={true}
            detectRetina={true}
          />
        }

        {children}
        <svg className='base-map' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" />

      </MapContainer>

      <IconButton
        style={{ position: 'absolute', top: 30, right: 30, zIndex: 1002, backgroundColor: 'whitesmoke', opacity: 0.9 }}
        onClick={handleMenuOpen}
      >
        <LayersIcon />
      </IconButton>

      {/* Popup Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={vfrLayerVisible}
                onChange={() => {
                  if (!vfrLayerVisible) {
                    setIfrLayerVisible(false);
                    setVfrLayerVisible(true);
                  } else {
                    setVfrLayerVisible(false);
                  }

                }}
              />
            }
            label="VFR Chart Layer"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={ifrLayerVisible}
                onChange={() => {
                  if (!ifrLayerVisible) {
                    setVfrLayerVisible(false);
                    setIfrLayerVisible(true);
                  } else {
                    setIfrLayerVisible(false);
                  }

                }}
              />
            }
            label="IFR Chart Layer"
          />
        </MenuItem>
      </Menu>

      {/* Dropshadow Filter */}
      <svg height="0" xmlns="http://www.w3.org/2000/svg">
        <filter id="dropshadow" height="130%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
          <feOffset dx="2" dy="2" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </svg>
    </>
  )
}


const getPaddingAndZoom = (isPhone) => {
  return isPhone ? {
    paddingTopLeft: [0, 0],
    paddingBottomRight: [0, 320],
    maxZoom: 15,
  } : {
    paddingTopLeft: [300, 25],
    paddingBottomRight: [25, 25],
    maxZoom: 15,
  }

}

