import { useRef } from "react";
import { MapContainer } from "react-leaflet";
import { useState, useEffect } from "react";
import * as protomapsL from 'protomaps-leaflet';
import themes from 'BaseMap/mapThemes';
import { drawTraces } from "BaseMap/traceLines";
import { drawFlightAirports } from "BaseMap/airports";

import './FlightMiniMap.css';

const disableMapIteractions = {
  doubleClickZoom: false,
  closePopupOnClick: false,
  dragging: false,
  zoomSnap: false,
  zoomDelta: false,
  trackResize: false,
  touchZoom: false,
  scrollWheelZoom: false,
  zoomControl: false,
}

export default function FlightMap(props) {
  const mapRef = useRef(null);
  const [traceLines, setTraceLines] = useState([]);


  let labelRules = [
    {
      dataLayer: "places",
      symbolizer: new protomapsL.CenteredTextSymbolizer({
        label_props: ["name:en"],
        fill: "transparent",
        width: 2,
        stroke: "transparent",
      })
    }
  ]


  useEffect(() => {
    setTimeout(() => {
      if (!mapRef.current) return;
      const map = mapRef.current;

      const baseLayer = protomapsL.leafletLayer({
        url: 'https://storage.googleapis.com/deadstick-public/proto/20240729.pmtiles',
        labelRules,
        paintRules: protomapsL.paintRules(themes.light)
      });
      baseLayer.addTo(map);


      drawTraces(props.flight.traces, mapRef, (data) => {
        setTraceLines(data.traceLines);
        map.fitBounds(data.bounds, { padding: [50, 50], maxZoom: 15 });
      });

      drawFlightAirports(map, props.flight);


    }, Math.random() * 1000);


  }, [mapRef.current, props.flight]);




  return (
    <>
      <MapContainer
        className="FlightMiniMap"
        ref={mapRef}
        center={[37.94, -95.967]}
        zoom={4}
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        {...disableMapIteractions}
      />

      <svg height="0" xmlns="http://www.w3.org/2000/svg">
        <filter id="dropshadow" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
          <feOffset dx="1" dy="1" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.3" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </svg>
    </>
  )
}
