import axios from 'axios';
import * as d3 from 'd3';
import logger from 'Lib/logger';
import TraceModel from 'Models/TraceModel';


export const getTrace = (traceUid, authHeaders) => {
  logger("getTrace", traceUid);
  let url = `/traces/${traceUid}.json`;

  return axios.get(url, authHeaders || {})
    .then(response => response.data)
    .then(data => {
      const trace = new TraceModel(data.trace);;

      logger('trace', trace)

      return trace;
    });
}


export const getTracePath = (trace) => {
  return axios.get(trace.getUrl())
    .then(response => {
      logger("getTracePath", response.data);
      const { data } = response;

      const path = data.map(d => ({
        t: +d[0],
        lat: d[1],
        lng: d[2],
        altFt: +d[3],
        distanceM: d[4],
        bearing: Math.round(+d[5]),
        x: d[6],
        y: d[7]
      }));

      path.forEach((d, i) => {
        if (i === 0 ) {
          d.groundSpeed = 0;
          return;
        }

        d.groundSpeed = d.distanceM / (d.t - path[i-1].t);
      })

      return path;

    })

}

export const logFlight = (trace, authHeaders) => {
  logger("logFlight", trace, authHeaders);

  const { path, ...traceData } = trace;

  return axios.post(
    '/flights/log.json',
    { trace: traceData },
    authHeaders || {})
    .then(response => response.data)
    .then(data => {
      const { flight } = data;

      logger('flight', flight)

      return flight;
    });
}