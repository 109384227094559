import { createTheme } from '@mui/material/styles';


export const themeData = {
  palette: {
    // mode: 'dark',
    primary: {
      // main: '#3C3C3C'
      main: '#FC5200'
    },
    secondary: {
      main: '#3C3C3C'
    }
  }
}

const theme = createTheme(themeData);

export default theme;