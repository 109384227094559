import React from "react";
import './Cell.css';
import { parseInputDate } from "Lib/format";

export default class Cell extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false
    };

    this.divRef = React.createRef();
    this.inputRef = React.createRef();
    this.hiddenInputRef = React.createRef();
  }

  handleChange = (e) => {
    const inputValue = e.target.value;
    // Allow only numbers and decimal point
    if (this.isNumber() && /^\d*\.?\d*$/.test(inputValue)) {
      this.props.onChange(inputValue);
    } else {
      this.props.onChange(inputValue);
    }
  }

  handleBlur = (e) => {
    console.log('handleBlur');
    if(this.state.isEditing){
      this.onStopEdit(e);
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.state.isEditing ? this.onStopEdit(e) : this.onStartEdit();
    }
    else if (e.key === 'Escape' && this.state.isEditing) {
      this.onCancelEdit();
    }
    else if (e.key === 'Delete' && !this.state.isEditing) {
      this.props.onChange('');
    }

    if(this.state.isEditing) return;

    // Arrow keys
    if (e.key === 'ArrowRight') {
      const nextTd = this.divRef.current.closest('td').nextSibling;
      if (!nextTd) return;
      nextTd.querySelector('input')?.focus();
      scrollIntoView(nextTd);
    }
    else if (e.key === 'ArrowLeft') {
      const previousTd = this.divRef.current.closest('td').previousSibling;
      if (!previousTd) return;
      scrollIntoView(previousTd);
      previousTd.querySelector('input')?.focus();
    }
    else if (e.key === 'ArrowUp') {
      const tdIndex = getElementIndex( this.divRef.current.closest('td') );
      const tr = this.divRef.current.closest('tr');
      const previousTr = tr.previousSibling;
      if(previousTr) {
        Array.from(previousTr.children)[tdIndex].querySelector('input').focus();
      }
    }
    else if (e.key === 'ArrowDown') {
      const tdIndex = getElementIndex( this.divRef.current.closest('td') );
      const tr = this.divRef.current.closest('tr');
      const nextTr = tr.nextSibling;
      if(nextTr) {
        Array.from(nextTr.children)[tdIndex].querySelector('input').focus();
      }
    }
  };

  onStartEdit = () => {
    this.setState(
      { isEditing: true },
      () => {
        this.inputRef.current.value = this.props.value ? this.props.value : '';
        this.inputRef.current.focus();
        this.inputRef.current.select();
      }
    );
  }

  onCancelEdit = () => {
    this.setState(
      { isEditing: false },
      () => {
        this.hiddenInputRef.current.focus();
      }
    );
  }

  onStopEdit = (e) => {
    const inputValue = e.target.value;
    this.props.onChange(inputValue);
    this.setState(
      { isEditing: false },
      () => {
        this.hiddenInputRef.current.focus();
      }
    );
  }

  handleCopy = (e) => {
    console.log("handleCopy");
    if (!this.state.isEditing) {
      e.preventDefault();
      navigator.clipboard.writeText(this.props.value);
    }
  };

  handlePaste = (e) => {
    if (!this.state.isEditing) {
      e.preventDefault();
      navigator.clipboard.readText()
        .then(text => {

          console.log('handlePaste', text);
          if (this.isNumber() && /^\d*\.?\d*$/.test(text)) {
            this.props.onChange(text);
          }
          else {
            this.props.onChange(text);
          }
        });

    }
  };

  isNumber = () => {
    return this.props.inputType ? 'numeric' : 'text';
  }

  handleValue = (value) => {
    return typeof value === 'date' ? parseInputDate(value) : value;
  }

  render() {
    const { value, format, inputProps, inputType, onChange, ...otherProps } = this.props;
    const { isEditing } = this.state;
    const formattedValue = format(value || '');


    return (
      <div
        className={`Cell Cell-${inputType}`}
        onCopy={this.handleCopy}
        onPaste={this.handlePaste}
        {...otherProps}
      >
        {
        isEditing ? (
          <input
            ref={this.inputRef}
            tabIndex={0}
            type="text"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown}
            className="edit"
            {...inputProps}
          />
        ) : (
          <div
            ref={this.divRef}
            className="format"
            onClick={this.onStartEdit}
            onFocus={() => {
              this.hiddenInputRef.current.focus();
              scrollIntoView(this.divRef.current);
            }}
          >
            {value ? formattedValue : ''}
            <input
              type="text"
              readOnly
              value={this.handleValue(value) || ''}
              ref={this.hiddenInputRef}
              onKeyDown={this.handleKeyDown}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0,
                pointerEvents: 'none',
              }}
            />
          </div>
        )}
      </div>
    );

  }

}



Cell.defaultProps = {
  onChange: () => {},
  inputProps: {},
  format: v => v,
  inputType: "text"
}


const getElementIndex = (element) =>{
  return Array.from(element.parentNode.children).indexOf(element);
}

const scrollIntoView = (element) => {
  setTimeout(() => {
    element.scrollIntoViewIfNeeded({
      behavior: 'smooth',  // Smooth scrolling
      block: 'nearest',    // Align vertically (optional)
      inline: 'center'     // Align horizontally
    })
}, 100);
}