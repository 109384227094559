import BaseModel from "Lib/BaseModel";


export default class LogbookEntryModel extends BaseModel {
  static hoursFields = [
    {field: "airplaneSingleEngineLandHours", displayName: "Single Engine Land"},
    {field: "airplaneMultiEngineLandHours", displayName: "MultiEngine Engine Land"},
    {field: "rotorcraftHelicopterHours", displayName: "Rotorcraft"},
    {field: "dualReceivedHours", displayName: "Dual Received"},
    {field: "pilotInCommandHours", displayName: "Pilot in Command"},
    {field: "secondInCommandHours", displayName: "Second in Command"},
    {field: "flightInstructorHours", displayName: "As Flight Instructor"},
    {field: "groundTrainerHours", displayName: "Ground Trainer"},
    {field: "dayHours", displayName: "Day"},
    {field: "nightHours", displayName: "Night"},
    {field: "crossCountryHours", displayName: "Cross Country"},
    {field: "actualInstrumentHours", displayName: "Actual Instrument"},
    {field: "simulatedInstrumentHours", displayName: "Simulated Instrument"},
    {field: "totalHours", displayName: "Total"},
  ]

  constructor(data) {
    super(data);

    this.departureAndArrivalAirports = this.departureAndArrivalAirports || [];

  }

}
