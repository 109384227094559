import axios from 'axios';
import FlightModel from '../../Models/FlightModel';
import logger from 'Lib/logger';
import TraceModel from 'Models/TraceModel';

export const getRecentFlights = () => {
  logger('getRecentFlights');
  return axios.get('/flights/recent.json')
    .then(response => response.data)
    .then(data => {

      const flights = data.flights.map(f => (
        new FlightModel(f)
      ));

      logger('getFlights', flights);

      return flights;
    });
}

export const getFlights = (authHeaders, options={ limit: 10, offset: 0}) => {
  options = { limit: 10, offset: 0, ...options };

  logger('getFlights');
  return axios.get(`/flights.json?limit=${options.limit}&offset=${options.offset}`, {...authHeaders, withCredentials: true})
    .then(response => response.data)
    .then(data => {

      const flights = data.flights.map(f => (
        new FlightModel(f)
      ));

      logger('getFlights', flights, data.count);

      return { flights, count: data.count };
    });
}


export const getFlight = (id, authHeaders) => {
  logger("getFlight", id);

  let url = `/flights/${id}.json`;

  return axios.get(url, authHeaders || {})
    .then(response => {
      const flight = new FlightModel(response.data.flight);
      logger('flight', flight);
      return flight;
    });
}


export const getTraceByFlight = (flightId, authHeaders) => {
  logger("getTraceByFlight", flightId);

  let url = `/traces/${flightId}/find_by_flight.json`;

  return axios.get(url, authHeaders || {})
    .then(response => {
      console.log('response.data', response.data);

      const traces = response.data.traces.map(t => new TraceModel(t));
      logger('traces', traces);
      return traces;
    });
}


export const getFlightByTraceUid = (traceUid, authHeaders) => {
  logger("getFlightByTraceUid", traceUid);

  let url = `/flights/${traceUid}.json`;

  return axios.get(url, authHeaders || {})
    .then(response => response.data)
    .then(data => {
      const flight = new FlightModel(data.flight);
      logger('flight', flight);
      return flight;
    });
}

export const createOrUpdateFlight = (data, authHeaders) => {
  if(!data.flight || !data.logbookEntry) {
    throw new Error('Invalid data. Both flight and logbookEntry are required');
  }

  logger("createOrUpdateFlight", data);

  let url = `/flights/new.json`;

  return axios.post(
    url, {
      flight: new FlightModel(data.flight).asJSON(),
      logbook_entry: new FlightModel(data.logbookEntry).asJSON()
    },
    authHeaders
  )
    .then(response => response.data)
    .then(data => {
      const flight = new FlightModel(data.flight);
      logger('flight', flight);
      return flight;
    });
}



export const deleteFlight = (flightId, authHeaders) => {
  return axios.delete(`/flights/${flightId}`, authHeaders)

}