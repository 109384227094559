import React from "react";
import { Box, ListItem, ListItemButton, Paper, List, Typography, ListItemText, CircularProgress, Divider, Chip, ListItemIcon } from "@mui/material";
import * as d3 from "d3";
import { Book, Check, FlightTakeoff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { formatDate, formatHours, formatTime } from "Lib/format";

export default function TraceList(props) {
  const { traces } = props;
  if (props.loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: 12, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size="small" />
        <Typography variant="caption">Loading...</Typography>
      </div>
    )
  }



  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1, width: '100%', overflowY: 'scroll' }}>
        <List sx={{ pt: 0 }}>
          <Divider />

          {
            traces.map((trace, i) => {
              // const airports = flight.logbookEntry?.departureAndArrivalAirports || [];

              const highlightFlight = () => {
                d3.selectAll(`.trace:not(.t-${trace.traceUid})`).style('opacity', 0.3);
                d3.selectAll(`.t-${trace.traceUid}`).style('opacity', 1);
              }

              const unhighlightFlight = () => {
                d3.selectAll(`.trace`).style('opacity', 1);
              }

              return (
                <React.Fragment key={i}>
                  <ListItemButton
                    key={trace.traceUid}
                    component={Link}
                    to={`/flights/${trace.registrationNumber}/traces/${trace.traceUid}`}
                    dense
                    onMouseEnter={highlightFlight}
                    onTouchStart={highlightFlight}
                    onMouseLeave={unhighlightFlight}
                    onTouchEnd={unhighlightFlight}
                  >
                    <ListItemText
                      disableTypography
                    >
                      <Box sx={{ display: 'flex', mb: 1 }}>
                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                          {formatDate(new Date(trace.takeoffT))}<br/>
                          <span style={{ fontSize: '0.75rem', color: '#777', fontWeight: '500' }}>
                            { formatTime(new Date(trace.takeoffT)) }
                            </span>
                        </Typography>
                        <div style={{ textAlign: 'right', flex: 1 }}>
                          <Chip
                            label={trace.registrationNumber} size="small"
                            color={trace.flightId ? 'primary' : 'default'}
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 11,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                            }}
                          />
                        </div>
                      </Box>

                      <Typography variant="caption" component="div" style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>

                          { [trace.departureArptId || 'Unknown', trace.destinationArptId || 'Unknown'].map((a,i) => <Chip key={i} label={a} size="small" sx={{ mr: '4px', fontSize: '0.55rem', p: 0, height: 18 }} />) }

                          {/* { (trace.departureArptId || 'Unknown') + ' ▸ ' + trace.destinationArptId } */}

                        </div>

                        <div style={{ textAlign: 'right' }}>
                          {formatHours(trace.getDurationHours())}h
                        </div>
                      </Typography>

                    </ListItemText>
                  </ListItemButton>
                  <Divider />

                </React.Fragment>
              )
            })
          }
        </List>
      </div>
    </div>
  )

}



const highlightFlight = (flightId) => {
}

const unhighlightFlight = (flightId) => {

}