import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'Auth/AuthProvider'

export default function SignOut() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  signOut()
    .then(() => {
      navigate('/');
    });

  return null;
}