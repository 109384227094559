import { TextField } from '@mui/material';
import { parseInputDate } from './format';


export default function DateField(props) {
  const { inputProps, InputLabelProps, value, ...rest} = props;
  let parsedValue = props.value ? parseInputDate(value) : '';

  return (
    <TextField
      defaultValue={value ? value.substring(0,10) : ""}
      inputProps={{ ...inputProps, type: 'date', max: "9999-12-31" }}
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      {...rest}
    />
  )
}
