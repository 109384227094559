import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { TextField, Container, Typography, Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from './AuthProvider';
import { useSnackbar } from 'Lib/Snackbar';

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const from = location.state?.from || '/';

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    signIn(email, password)
      .then(user => {
        setIsLoading(false);
        if (user) {
          navigate(from, { replace: true });
        } else {
          showSnackbar('Invalid username or password', { severity: 'error' });
        }
      })
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <LoadingButton
            loading={isLoading}
            loadingIndicator="Signing In…"
            type="submit"
            fullWidth
            variant="contained"
            elevation={0}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>

          <Button
            component={Link}
            to="/sign-up"
            fullWidth
            variant="text"
            sx={{ mt: 1, mb: 1 }}
          >
            Sign Up
          </Button>

        </Box>
      </Box>
    </Container>
  );
};

export default SignIn;