

import React, { useRef, useState, createContext, useContext, useMemo, useCallback } from "react";

export const FlightMapContext = createContext();


export default function FlightMapProvider(props) {
  const mapRef = useRef();
  const [mapTraces, setMapTraces] = useState([]);
  const [mapFlights, setMapFlights] = useState([]);
  const [mapAirports, setMapAirports] = useState([]);
  const [brushMarker, setBrushMarker] = useState(null);

  return (
    <FlightMapContext.Provider value={{
      mapRef,
      mapTraces,
      setMapTraces,
      mapFlights,
      setMapFlights,
      brushMarker,
      setBrushMarker
    }}>
    {props.children}
    </FlightMapContext.Provider>
  );

}


export const useFlightMap = () => useContext(FlightMapContext);