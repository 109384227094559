import { Divider, IconButton } from "@mui/material";
import { getFlight, createOrUpdateFlight, deleteFlight } from "./FlightsApi";
import { Delete, Mic } from "@mui/icons-material";
import { useAuth } from "Auth/AuthProvider";
import { useEffect, useState } from 'react';
import { useFlightMap } from 'FlightMap/FlightMap';
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "Lib/Snackbar";
import { LinearProgress } from '@mui/material';
import CardHeader from "../Shared/CardHeader";
import FlightForm from "./FlightForm";
import StickyHeader from "FlightMap/Shared/Sticky";


export default function FlightEdit(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { authHeaders } = useAuth();
  // const { setMapTraces } = useFlightMap();

  const [loading, setLoading] = useState(true);
  const [flight, setFlight] = useState(null);



  useEffect(() => {
    setLoading(true);


    getFlight(params.flightId, authHeaders)
      .then(flight => {
        setFlight(flight);
        setLoading(false);

        // setMapTraces(flight.traces);
      });
  }, [params.traceUid]);


  const onSubmit = (data) => {
    showSnackbar("Updating flight...");

    data.id = flight.id;

    createOrUpdateFlight(data, authHeaders)
      .then(flight => navigate(`/flights/${flight.id}`))
      .catch(error => {
        console.error(error);
        showSnackbar("Error creating flight", { severity: 'error' });
      })
  }




  const onClickDelete = () => {
    if(window.confirm("Are you sure you want to delete this flight?")) {
      deleteFlight(flight.id, authHeaders)
        .then(() => {
          showSnackbar("Flight deleted");
          navigate('/flights');
        })
    }
  };

  return (
    <>
      <StickyHeader>
        <CardHeader
          title="Edit Flight"
          toolbar={
            <>
              <IconButton onClick={onClickDelete} >
            <Delete />
              </IconButton>
            <IconButton color="primary">
              <Mic />
            </IconButton>
            </>
          }
          backTo={`/flights/${params.flightId}`}
        />

        {loading ? <LinearProgress /> : <Divider />}
      </StickyHeader>
      {
        !loading &&
        <FlightForm
          flight={flight}
          logbookEntry={flight.logbookEntry}
          onSubmit={onSubmit}
          onCancel={() => navigate(`/flights/${flight.id}`)}
        />
      }
    </>
  )

}