// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthProvider from './Auth/AuthProvider';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import SignOut from './Auth/SignOut';
import Profile from './Profile/Profile';
import ProfileEdit from './Profile/ProfileEdit';
import Dashboard from './Dashboard/Dashboard';
import FlightMap from './FlightMap/FlightMap';
import AirportsAutocomplete from './FlightMap/Flights/AirportsAutocomplete';
import Snackbar from 'Lib/Snackbar';
import Logbook from 'Logbook/Logbook';
import LogbookCreatePage from 'Logbook/LogbookCreatePage';
import CssBaseline from "@mui/material/CssBaseline";
import ProfileProvider from 'Profile/ProfileProvider';
import ErrorBoundary from 'Lib/ErrorBoundary';
import FlightView from 'Flights/FlightView';
import FlightCreate from 'FlightMap/Flights/FlightCreate';
import FlightMapProvider from 'FlightMap/FlightMapProvider';
import EditFlightDetail from 'FlightMap/Flights/FlightEdit';
import FlightsIndex from 'FlightMap/Flights/FlightsIndex';


function App() {

  if (/iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream) {
    document.body.style.paddingBottom = 'calc(env(safe-area-inset-bottom) + 40px)';
  }

  return (
    <ErrorBoundary>
      <Snackbar>
        <CssBaseline />
        <Router>
          <AuthProvider>
            <ProfileProvider>
              <Routes>

                <Route path="/edit-airports" element={<AirportsAutocomplete />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/sign-out" element={<SignOut />} />

                <Route path="/" element={<Dashboard />} />
                {/* <Route path="/flights" element={<FlightsIndex />} /> */}

                <Route path="/flights/new" element={<FlightCreate />} />
                <Route path="/flights/:flightId" element={<FlightMapProvider><FlightView /></FlightMapProvider>} />
                <Route path="/flights/:flightId/edit" element={<EditFlightDetail />}/>
                <Route path="/flights/*" element={<FlightMapProvider><FlightMap /></FlightMapProvider>} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/edit" element={<ProfileEdit />} />

                <Route path="/logbook/page/new" element={<LogbookCreatePage />} />
                <Route path="/logbook/page/:pageBaseOne" element={<Logbook />} />

                {/* <Route path="/" element={<Navigate to="/flights" />} /> */}
                <Route path="/logbook" element={<Navigate to="/logbook/page/1" />} />


              </Routes>
            </ProfileProvider>
          </AuthProvider>
        </Router>
      </Snackbar>
    </ErrorBoundary>
  );
}

export default App;