import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import logger from 'Lib/logger';
import { LinearProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'Auth/AuthProvider';
import { loadProfile } from './ProfileApi';
import { Navigate } from 'react-router-dom';

export const ProfileContext = createContext();

export default function ProfileProvider({children}){
  const { isSignedIn, authHeaders } = useAuth();
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  const [profile, setProfile] = useState(null);
  const [flightStats, setFlightStats] = useState(null);


  useEffect(() => {
    if(!isSignedIn) {
      setIsLoadingProfile(false);
      return;
    }

    loadProfile(authHeaders)
      .then(data => {
        setProfile(data.profile);
        setFlightStats(data.flightStats);
        setIsLoadingProfile(false);
      });

  }, [isSignedIn]);



  return (
    <ProfileContext.Provider value={{ profile, isLoadingProfile, setProfile, flightStats }}>
      { children }

      {
        profile && !profile.isProfileComplete() &&
          <Navigate to="/profile/edit" />
      }

    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);