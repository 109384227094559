import { Box } from '@mui/material';;


export default function FlightPhotos(props) {
  return (
    <Box display="flex">
      {/* Thumbnails */}
      {props.photoUrls.map((url, index) => (
        <Box key={index} width={80} height={80} borderRadius="8px" overflow="hidden" mr={1} mb={1}>
          <img src={url} alt={`Uploaded ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
      ))}
    </Box>
  );
}
