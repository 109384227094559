import React, { useState } from 'react';
import axios from 'axios';
import { Button, Avatar, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useAuth } from 'Auth/AuthProvider';
import logger from 'Lib/logger';
import { useSnackbar } from 'Lib/Snackbar';

const Input = styled('input')({
  display: 'none',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 126,
  height: 126,
  border: '2px solid lightgray',
  position: 'relative',
  '&:hover, &:focus': {
    cursor: 'pointer',
    '&::after': {
      fontSize: 12,
      content: '"Choose a photo"',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1),
      width: '100%',
      height: '100%',
    },
    '&::after > svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

const ProfilePhotoUpload = (props) => {
  const { authHeaders } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [preview, setPreview] = useState(null);


  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    setProfilePhoto(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('profile_photo', profilePhoto);
    logger("authHeaders", authHeaders)

    try {
      const response = await axios.patch('/profiles/update_profile_photo', formData, {
        headers: {
          ...authHeaders.headers,
          'Content-Type': 'multipart/form-data',
        },
      });

      logger(response.data);

      showSnackbar('Photo updated');

      props.onUpdate(response.data.profile_photo_url);

    } catch (error) {
      console.error('Error uploading profile photo:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item>
          <label htmlFor="profilePhoto">
            <Input
              accept="image/*"
              id="profilePhoto"
              type="file"
              onChange={handleProfilePhotoChange}
            />
            <StyledAvatar
              alt="Profile Photo"
              src={preview ? preview : ( props.profilePhotoUrl ? props.profilePhotoUrl : null )}
              tabIndex={0}
            >
              {!preview && <CameraAltIcon />}
            </StyledAvatar>
          </label>
        </Grid>
        <Grid item>
          <Button size="small" type="submit" variant="contained" color="primary" disabled={!profilePhoto}>
            Update Photo
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProfilePhotoUpload;