import axios from 'axios';
import logger from 'Lib/logger';
import TraceModel from 'Models/TraceModel';


export const searchTraces = (registationNumber, options, authHeaders) => {
  logger("searchTraces", registationNumber, options, authHeaders);

  let url = `/traces/${registationNumber}/search.json?limit=${options.limit}&offset=${options.offset}`;

  return axios.get(url, authHeaders || {})
    .then(response => {
      console.log('response.data', response.data);
      const { count } = response.data;

      const traces = response.data.traces.map(t => new TraceModel(t));

      return {
        traces,
        count,
      };
    });
}