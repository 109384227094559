export const toCamelCase = (key) => {
  return key.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
}

export const toSnakeCase = (key) => {
  return key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
}


export const convertKeysToCamelCase = (snakeCaseObject) => {
  const camelCaseObject = {};
  for (const key in snakeCaseObject) {
    if (Object.hasOwnProperty.call(snakeCaseObject, key)) {
      const camelCaseKey = toCamelCase(key);
      camelCaseObject[camelCaseKey] = snakeCaseObject[key];
    }
  }
  return camelCaseObject;
};


export const convertKeysToSnakeCase = (camelCaseObject) => {
  const snakeCaseObject = {};
  for (const key in camelCaseObject) {
    if (Object.hasOwnProperty.call(camelCaseObject, key)) {
      const snakeCaseKey = toSnakeCase(key);;
      snakeCaseObject[snakeCaseKey] = camelCaseObject[key];
    }
  }
  return snakeCaseObject;
};
