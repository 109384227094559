import { Card, Typography } from '@mui/material';

export default function ProfileSectionCard(props) {
  const sx = props.sx || {};

  return (
    <Card elevation={0} sx={{ p: 2, border: '1px solid #f1f1f1', ...sx }}>
      <Typography variant="caption" sx={{ display: 'block', mb: 2 }}>
        {props.title}
      </Typography>
      {props.children}
    </Card>
  )
}
