import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Card, CardContent, CardHeader, Avatar, Box, ListItem, ListItemText, Button } from '@mui/material';
import Layout from 'Layout/Layout';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { getRecentFlights } from 'FlightMap/Flights/FlightsApi';
import FlightPost from 'Dashboard/FlightPost';
import { useAuth } from 'Auth/AuthProvider';
import ProfileCard from './ProfileCard';
import SignUpCard from './SignUpCard';
import { Link } from 'react-router-dom';


const Dashboard = () => {
  const { isSignedIn } = useAuth();
  const [flights, setFlights] = useState([]);

  useEffect(() => {
    getRecentFlights()
      .then(flights => setFlights(flights));
  }, []);


  return (
    <Layout>
      <Grid container spacing={2}>

        <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
          {
            isSignedIn ? <>
              <ProfileCard />
              <Button
              component={Link}
              variant="contained" fullWidth sx={{ mt: 2, boxShadow: 'none' }}
              to="/flights/new">Log Flight</Button>
            </> : <SignUpCard />
          }
        </Grid>

        <Grid item xs={12} md={9}>
          <Box sx={{ mt: 6 }}>
            {flights.map((flight) => (
              <FlightPost key={flight.id} flight={flight} />
            ))}
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={3}>
          <Typography variant="h6">Who to Follow</Typography>
          <List>
            {suggestedPilots.map((pilot, index) => (
              <ListItem key={index}>
                <Avatar src={pilot.avatar} />
                <ListItemText primary={pilot.name} />
              </ListItem>
            ))}
          </List>
        </Grid> */}
      </Grid>
    </Layout>
  );
};

export default Dashboard;