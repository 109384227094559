import axios from 'axios';
import FlightModel from 'Models/FlightModel';
import logger from 'Lib/logger';


export const LOGBOOK_ENTRIES_PER_PAGE = 14;

// export const getLogbookTotals = (authHeaders) => {
//   logger('getLogbookTotals');
//   return axios.get('/logbook_entries/totals.json', {...authHeaders, withCredentials: true})
//     .then(response => {
//       const totals = new LogbookTotalsModel(response.data.totals);
//       logger('getLogbookTotals', totals);

//       return { totals, dateRange: response.data.date_range };
//     });
// };




export const getFlightsWithLogbookEntries = (page, authHeaders) => {
  logger('getFliggetFlightsWithLogbookEntries');
  return axios.get('/logbook_entries.json', {
    params: { limit: LOGBOOK_ENTRIES_PER_PAGE, offset: page * LOGBOOK_ENTRIES_PER_PAGE },
    ...authHeaders,
    withCredentials: true
  })
    .then(response => {

      const { total } = response.data;
      const flights = response.data.flights.map(f => (
        new FlightModel(f)
      ));

      logger('getFlightsWithLogbookEntries', { flights, total });

      return { flights, total };
    });
}