import React from "react";
import { Box, ListItem, ListItemButton, Paper, List, Typography, ListItemText, CircularProgress, Divider, Chip } from "@mui/material";
import * as d3 from "d3";
import { Book, Check, FlightTakeoff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { formatDate, formatHours, formatTime } from "Lib/format";

export default function FlightList(props) {
  const { flights } = props;
  if (props.loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: 12, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size="small" />
        <Typography variant="caption">Loading...</Typography>
      </div>
    )
  }

  // if (!props.flights.length && !props.registrationNumber) {
  //   return (
  //     <div style={{ display: 'flex', flexDirection: 'column', padding: 12, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
  //       <Typography variant="caption">Search for a Tail Number</Typography>
  //     </div>
  //   )

  // }


  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1, width: '100%', overflowY: 'scroll' }}>
        <List sx={{ pt: 0 }}>

          {
            flights.map((flight, i) => {
              const airports = flight.logbookEntry?.departureAndArrivalAirports || [];

              const highlightFlight = () => {
                d3.selectAll(`.flight-curve:not(.t-${flight.id})`).style('opacity', 0.3);
                d3.selectAll(`.flight-${flight.id}`).style('opacity', 1);
              }

              const unhighlightFlight = () => {
                d3.selectAll(`.flight-curve`).style('opacity', 1);
              }

              return (
                <React.Fragment key={i}>
                  <ListItemButton
                    key={flight.id}
                    component={Link}
                    to={`/flights/${flight.id}`}
                    dense
                    onMouseEnter={highlightFlight}
                    onTouchStart={highlightFlight}
                    onMouseLeave={unhighlightFlight}
                    onTouchEnd={unhighlightFlight}
                  >
                    <ListItemText
                      disableTypography
                    >
                      <Box  sx={{ display: 'flex', mb: 1 }}>
                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                          {formatDate(flight.getDepartureUTC())}<br/>
                          <span style={{ fontSize: '0.75rem', color: '#777', fontWeight: '500' }}>{formatTime(flight.getDepartureUTC())}</span>
                        </Typography>
                        <div style={{ textAlign: 'right', flex: 1 }}>
                          <Chip
                            label={flight.registrationNumber} size="small"
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 11,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                            }}
                          />
                        </div>
                      </Box>

                      <Typography variant="caption" component="div" style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>

                          { airports.map((a,i) => <Chip key={i} label={a} size="small" sx={{ mr: '4px', fontSize: '0.55rem', p: 0, height: 18 }} />) }
                        </div>

                        <div style={{ textAlign: 'right' }}>
                          {flight.logbookEntry?.totalHours}h

                        </div>
                      </Typography>

                    </ListItemText>
                  </ListItemButton>
                  <Divider />

                </React.Fragment>
              )
            })
          }
        </List>
      </div>
    </div>
  )

}



const highlightFlight = (flightId) => {
}

const unhighlightFlight = (flightId) => {

}