import BaseModel from "Lib/BaseModel";
import { convertKeysToCamelCase } from "Lib/convertCase";


export default class MedicalExpirationsModel extends BaseModel {
  constructor(data) {
    super(data || {});


    if(this.currentPrivileges) {
      this.currentPrivileges = new MedicalPrivilegesModel(this.currentPrivileges);
    }

    if(this.nextPrivileges) {
      this.nextPrivileges = new MedicalPrivilegesModel(this.nextPrivileges);
    }

    this.expirations = this.expirations.map(e => new MedicalPrivilegesModel(e));
  }

}


class MedicalPrivilegesModel extends BaseModel {
  constructor(data) {
    super(data || {});

    this.expirationDate = new Date(this.expirationDate);
  }
}