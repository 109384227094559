import WjCard from "Lib/WjCard";
import { Stat } from "./Profile";
import { shortDateFormat } from "Lib/format";
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";


export default function MedicalTracker(props) {
  const { profile } = props;

  if (!profile.medicalExpirationDates) {
    return (
      <WjCard sx={{ mt: 1, p: 2 }}>
        <Typography variant="body1" sx={{ color: '#999', fontSize: '0.75rem', fontWeight: 'bold' }}>
          Update your medical information to track your medical certificate status.
        </Typography>
      </WjCard>
    );
  }



  return (
    <WjCard sx={{ mt: 1, p: 2 }}>

      <Stat label="Medical Type" metric={profile.medicalType} />

      <Stat label="Medical Exam Date" metric={profile.medicalDate} format={shortDateFormat} />

      <Stat label="Current Privileges" metric={profile.medicalExpirationDates.currentPrivileges.medicalType} />

      <Stat label="Current Privileges Expiration Date" metric={profile.medicalExpirationDates.currentPrivileges.expirationDate} format={shortDateFormat} />

      <Stat label="Next Privileges" metric={profile.medicalExpirationDates.nextPrivileges?.medicalType || 'None'} />

      <Button variant="outlined" fullWidth sx={{ mt: 2 }} component="a" color="primary" target="_blank" href="https://designee.faa.gov/#/designeeLocator">
        Find a DME
      </Button>

    </WjCard>
  );
}