import { Paper, Box, styled } from "@mui/material";
import './MapCard.css';
import { SearchTracesBox } from "FlightMap/SearchTraces/SearchTracesBox";

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1000,
  padding: 0,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    postition: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
    borderRadiusTopLeft: 5,
    borderRadiusTopRight: 5,
  },
  [theme.breakpoints.up('sm')]: {
    left: 20,
    top: 20,
    bottom: 42,
    borderRadius: 5,
    width: 260
  }
}));


const MapPaper = styled(Paper)(({ theme }) => ({
  overflowX: 'hidden',
  border: '1px solid #f1f1f1',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('sm')]: {
    height: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    maxHeight: '100%'
  }
}));

export default function MapCard(props) {
  return (
    <Root>
      <MapPaper  sx={{ overflowX: 'hidden', pb: 1}}>
        {props.children}
      </MapPaper>
    </Root>
  );
}
